import React, { Fragment } from 'react';
import { subDays } from 'date-fns';
import {
    Row,
    Col,
    Container
  } from "reactstrap";
import {
    setAppCustomTitle
} from "../../../reducers/ThemeOptions";
import { connect } from "react-redux";
import DateFilter from '../../../Common/DateFilter';
import Common from '../../Common';
import "./assets/index.css";
import {AgentDashboardSummaryBox} from "./Summary"
import { Graphdashboard } from './Graphdashboard';
import requestApi from '../../../services/requestApi.service';
import { getDarkThemeColor, getOrganizationInfo, isMobile } from '../../../Common/Appcommon';
import Switch from "react-switch";  
import Select from "react-select";
import { paymentType } from "./constant";
import { fi, th } from 'date-fns/locale';
import Loading from '../../../Common/Loading';
class AgentDashboard extends React.Component {
    constructor(props) {
        super(props);
        const startDate = subDays(new Date(), 29); // Default to 7 days ago
        const endDate = new Date(); 
        this.state = {
            dateFilter: {
                Start_DateTime: [startDate, endDate],
                noofdays: 7
            },
            summary : [],
            Loader : false,
            demo:false,
            isMobile:isMobile(),
            isChecked:false,
            defaultpaymentType:paymentType[0],
            defaultCustomer:{label:"All",value:"all"},
            customerList:[],
        };
    }
    componentDidMount= () =>{
        this.getSummaryBox();
        this.getSubaccountContent();
    }
    getSummaryBox = async () => {
        this.setState({Loader:true});
        let { demo,defaultpaymentType,defaultCustomer,dateFilter } = this.state;
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_AGENT_DASHBOARD_SUMMARY",
            isOtherApiReq: true,
            datefilter:dateFilter,
            demo: demo,
            paymentType:defaultpaymentType,
            customerName:defaultCustomer,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data
                this.setState({ summary: data,Loader:false});
            }
        } catch (error) {
            this.setState({Loader:false});
            console.error("Error fetching dashboard summary:", error);
        }
    }

    handleChange = (checked) => {
        this.setState(demo => ({ demo: !demo.demo }));
    }

    handleDateChange = async (startDate, endDate) => {
        if (startDate && endDate) {
            let dateobj = {};
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            const timeDifference = endDate.getTime() - startDate.getTime();
            const numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
            dateobj['Start_DateTime'] = [startDate, endDate];
            dateobj['noofdays'] = numberOfDays;
            this.setState({ 
                dateFilter: dateobj, 
                Loader: true 
            }, async () => {
                try {
                    // Run all the async functions in parallel
                    this.getSummaryBox();
                } catch (error) {
                    console.error('Error during date change operations:', error);
                } finally {
                    // Ensure the loader is turned off after all operations are complete
                    this.setState({ Loader: false });
                }
            });
        }
    }
    getTitleContent = () => {
        return(
            <>
                <span>Overview</span>
            </>
        )
    } 
    getSubaccountContent = async () => {
        let { defaultpaymentType } = this.state;
        let agentId = getOrganizationInfo("Account_ID");
        var fetchFilter = ""
        console.log(defaultpaymentType)
        if(defaultpaymentType.value !== "all"){
            if(defaultpaymentType.value == "Trial"){
                fetchFilter =  { value: 'type,Trial', label: 'Trial' }
            }else if(defaultpaymentType.value == "Customer"){
                fetchFilter =  { value: 'type,Customer', label: 'Paid' }
            }           
        }
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "LOAD_SUBACCOUNTS",
            "AGENT_ID": agentId,
            "filter": fetchFilter,
        }
        let formresponse = await requestApi.processServerRequest(params, "WebV2");
        if(formresponse.issuccess){
            let data = formresponse.data[0].length > 0 ? formresponse.data[0] : [];
            let customerList = [];
            if(data.length > 0){
                 customerList = data.map((item) => {
                    return {
                        label: item.account_name,
                        value: item.appid
                    }
                });
                customerList.unshift({label:"All",value:"all"});
            }else{
                this.setState({defaultCustomer:null})
            }
            this.setState({customerList});
        } 
    }

    onChange = (type,selectedOption) => {
        if(type === "paymentType"){
            this.setState({defaultpaymentType:selectedOption});
            this.setState({defaultCustomer:{label:"All",value:"all"}});
        }else if(type === "customer"){
            this.setState({defaultCustomer:selectedOption});
        }
    }
    
    componentDidUpdate(prevProps,prevState) {
        if (
            prevState.demo !== this.state.demo ||
            prevState.defaultpaymentType !== this.state.defaultpaymentType ||
            prevState.defaultCustomer !== this.state.defaultCustomer
        ) {
            this.getSummaryBox();
            this.getSubaccountContent();
        }
    }
    
    getTitleBody = () => {
        return(
        <Fragment>
            <div className={`d-flex justify-content-end ${this.state.isMobile && 'p-2'} agent-dashboard-header`}>
                <div className='d-flex  align-items-center me-2'>
                    <span className='demon-header me-2'>
                        Demo
                    </span>
                    <span className='mt-2 me-2 d-inline-block'>
                        <Switch
                            height={20}
                            width={40}
                            onColor={getDarkThemeColor()}
                            className=''
                            disabled={this.state.Loader}
                            checked={this.state.demo} 
                            onChange={this.handleChange}
                        />
                    </span>
                    <span>

                    </span>
                </div>
                <div className='me-2 filter-width'>
                    <Select
                        options={paymentType}
                        className="basic-multi-select wa-select__control"
                        classNamePrefix="wa-select"
                        value={this.state.defaultpaymentType}
                        menuPlacement="auto"
                        isSearchable={false}
                        onChange={(selectedOption) => this.onChange('paymentType', selectedOption)}                    />
                </div>
                <div className='me-2 filter-width'>
                    <Select
                        options={this.state.customerList}
                        className="basic-multi-select wa-select__control"
                        classNamePrefix="wa-select"
                        value={this.state.defaultCustomer}
                        menuPlacement="auto"
                        isSearchable={false}
                        onChange={(selectedOption) =>  this.onChange('customer', selectedOption)}
                    />
                </div>
                <div>
                    <DateFilter  handleDateChange={this.handleDateChange} defaultSelectIndex={3}/>
                </div>
            </div>
        </Fragment>
        )
    }
    mainContent = () => {
        let {summary,Loader,demo,dateFilter} = this.state;
        return(
            <Fragment>
                <div className='dashboard-container w-100'>
                    <Loading 
                        showLoader = {Loader} 
                        mainContent ={
                            <AgentDashboardSummaryBox summary = {summary} />
                        }
                    />
                    <Graphdashboard demo={demo} paymentType={this.state.defaultpaymentType} customerName = {this.state.defaultCustomer} dateFilter = {dateFilter}/>
                </div>
            </Fragment>
        );
    }

    render() {
        let {summary,Loader,demo} = this.state;
        return (
            <Fragment>
                <Common
                    mainLoader={   
                        Loader
                    }
                    title={"Overview"}
                    titleContent={
                        this.getTitleContent()
                    }
                    demoPopup = { demo }
                    demoHandler = {this.handleChange}
                    titleBody={
                        !demo && this.getTitleBody()
                    }
                    mainContent={
                        this.mainContent()
                    }
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentDashboard);
