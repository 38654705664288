import React, { Fragment, useEffect, useState } from "react";
import twilioimg from "../../assets/utils/images/Intagration image/twilio.svg"
import cx from "classnames";
import { Button, Col, Row} from "reactstrap";
import {cardData} from './Constants';


const Twilio = (props) => {
    let {isInstalation,buttonValue,unInstall} = props;   
    const [carddata,setData] = useState(cardData);
    let key = '';
    let foundData = carddata.find((data) => data.heading === "Twilio");
    if (foundData) {
     key = foundData.component.button.key;
    }    
    const onOpenModal = (type,openModule = false) => {
            isInstalation(type,openModule,'Twilio',"SMS");
    }


    return (
        <Fragment>
            <div className="agent-details">
                {
                    <Fragment>
                        <div className="ghl-heading">
                            <div className="ghl-details">
                            <Row>
                                <Col md={1}>
                                <span className="card-image-status">
                                    <img src={twilioimg} className="intagration-card-detail-img"/>
                                </span>
                                </Col>
                                <Col md={8} className="p-1">
                                <span className="heading">
                                    Twilio
                                </span>
                                <span className="tag">
                                    Voice & video
                                </span>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                {!buttonValue.includes(foundData.heading) ? (<Button
                                    name={"cancel"}
                                    className={cx("badge-theme badge-btn m-4")}
                                    onClick={(e) => { onOpenModal(key,true) }}
                                >
                                    <i class="fi fi-br-plus-small"></i>
                                    {<span>{"Install App"}</span>}
                                </Button>):(<Button
                                    name={"cancel"}
                                    className={cx("badge-theme badge-btn m-4")}
                                    onClick={(e) => { unInstall(key) }}
                                >
                                    <i class="fi fi-br-plus-small"></i>
                                    {<span>{"Uninstall App"}</span>}
                                </Button>)}
                                </Col>
                                </Row>                          
                            </div>
                        </div>
                        <div className="card-fields">
                            <span className="field-name">Website</span>
                            <span>
                            <a href="https://www.twilio.com/" target="_blank">https://www.twilio.com/</a>
                            </span>
                        </div>
                        <div className="card-fields">
                            <span className="field-name">Overview</span>
                            <span className="subheading">The Voicemetrics integration with HighLevel empowers agencies to streamline client interactions and calendar management. By connecting OneInbox’s advanced AI calling capabilities with HighLevel’s robust CRM and calendar tools, businesses can automate scheduling, client follow-ups, and appointment reminders. This integration simplifies workflows and enhances team productivity, even in high-demand scenarios.</span>
                        </div>
                        <div className="card-fields">
                            <span className="field-name">Key Benefits</span>
                            <span className="subheading ver-2px">
                                <span className="col-pri"> Automated Appointment Management </span>
                                Allow AI-powered agents to schedule and reschedule appointments directly within HighLevel, leveraging real-time calendar availability for seamless booking.
                                <span className="col-pri"> Streamlined Client Interactions </span>
                                Enhance customer satisfaction with 24/7 AI support, ensuring clients can book and update appointments whenever needed.
                                <span className="col-pri"> Integrated Workflows </span>
                                Sync appointments across HighLevel calendars, ensuring your agency operates with up-to-date information without manual effort.
                                </span>
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
        
    )
}

export default Twilio;