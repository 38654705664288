import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { Mention } from 'primereact/mention';
import cx from "classnames";

import {
    getFieldsByFormname
} from "../../../Common/Appcommon";

const CratioMention = (props) => {
    let {
        name,
        value,
        fieldkey,
        type,
        formname,
        mentionTrigger,
        handleOnChange,
        control,
        inputID,
        customClassName,
        placeholder,
        suggestionList,
    } = props;
    const [suggestions, setSuggestions] = useState([]);
    const [options, setOptions] = useState([]);
    let orgFormName = process.env.REACT_APP_ORGANIZATION_FORMNAME;

    useEffect(() => {
        (async () => {
            if(suggestionList !== ""){
                setOptions(suggestionList);
            }else {
                // let selectedfields = await getFieldsByFormname(formname, "crm_template_operation");
                // let orgfields = await getFieldsByFormname(orgFormName, "organization");
                // const mergedArray = [...selectedfields, ...orgfields.filter(obj2 => !selectedfields.some(obj1 => obj1.value === obj2.value))];
                // setOptions(mergedArray);
            }
        })();
    }, [formname]);

    const onSearch = (event) => {
        //in a real application, make a request to a remote url with the query and return suggestions, for demo we filter at client side
        setTimeout(() => {
            const query = event.query;
            let suggestions;
            if (!query.trim().length) {
                suggestions = [...options];
            }else {
                suggestions = options.filter((field) => {
                    return field.id.toLowerCase().startsWith(query.toLowerCase());;
                });
            }
            setSuggestions(suggestions);
        }, 250);
    }

    const itemTemplate = (suggestion) => {
        return (
            <span className={"d-flex flex-column custom-suggestion-editor"}>
                {suggestion.name}
            </span>
        );
    }
    const onMentionChange = (e) => {
        /* 
            '#$' to '$' for '#$ContactName$' to '$ContactName$' for replace '#$ to $'
            '#[' to '$' for '#[@LeadOwner@Name@]' to '[@LeadOwner@Name@]' 
            '&nbsp;' to '$' for '[@LeadOwner@Name@]' to '[@Lead Owner@Name@]' givin white space for variables
        */
        let name = e.target.name;
        let value = e.target.value.replace("#$", "$").replace("#[", "[").replace("#@", "@").replaceAll("&nbsp;", " ");
        handleOnChange(name, value);
    }

    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={{ required: value !== undefined && value !== "" ? false : props.message !== undefined ? props.message : "Please choose " + name }}
                render={({ field }) => (
                    <Mention 
                        name = {name}
                        inputId={inputID}
                        value={value} 
                        onChange={(e) => {
                            onMentionChange(e);
                            field.onChange(e);
                        }} 
                        suggestions={suggestions} 
                        onSearch={onSearch} 
                        field={fieldkey} 
                        rows={props.rows !== undefined ? props.rows : 4} 
                        cols={props.cols !== undefined ? props.cols : 40} 
                        itemTemplate={itemTemplate}
                        inputClassName={customClassName}
                        trigger={mentionTrigger}
                        placeholder={placeholder}
                    />
                )}
            />  
        </>
    )
}

export default CratioMention;