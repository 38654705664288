import React, { Component, Fragment } from "react";
import { Row, Col, CardBody, Button, Modal, ModalBody, ModalHeader,Input, Card} from "reactstrap";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import requestApiService from "../../services/requestApi.service";
import Common from "../Common";
import ListTable from "../../Common/ListTable";
import aiData from "../../assets/utils/images/agent_no_records.png"
import { showToast, prepareCustomTableData, exportFormData,parseJSON, formatDateTime,confirmBox,isMobile,getSessionValue,getPerRecordOption, stopAudio, formatTime, capitalizeFirstLetter, translateContent} from '../../Common/Appcommon';
import Loading from '../../Common/Loading';
import NoRecordFound from '../Common/NoRecordFound';
import Select from "react-select";
import parseHtml from 'html-react-parser';
import CallLogAnalysisSummary from "./CallLogAnalysisSummary";
import { subDays } from "date-fns";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Filter from "./Filter";
import { exportRecordsCounts, filterOption1, filterOption2 } from "./constant";
import CustomListTable from "./../../Common/CustomListTable";
import exportIcon from "../../assets/utils/images/file-export.png";
class CallLog extends Component {
  startDate = subDays(new Date(), 7); 
  endDate = new Date(); 
  dateFilter = {Start_DateTime: [this.startDate, this.endDate],noofdays: 7,};
  exportRecordsCounts = getSessionValue('exportLimit');
  exportRecordsCounts = (this.exportRecordsCounts !== '' && this.exportRecordsCounts !== undefined && this.exportRecordsCounts !== 'undefined' && this.exportRecordsCounts !== null) ? parseJSON(this.exportRecordsCounts) : [];
  rowOptions = getPerRecordOption();

  constructor(props) {
    super(props);
    const mobile = isMobile();
       this.state = {
                searchStr: "",
                data: [], // Assuming data is fetched and managed here
                columns: [], // Define columns for your table
                showDetailed: false,
                isModalOpen: false,
                showButtonLoading: false,
                showLoader: false,
                isPlaying: false,
                totalCount: 0, 
                exportLimit: this.exportRecordsCounts[0].value,
                exportRecordsCounts:this.exportRecordsCounts,
                audioStates: {},
                isDisabled: false,
                outboundFilter: '',
                statusFilter: '',
                dateFilter: this.dateFilter,//{Start_DateTime: subDays(new Date(), 1),noofdays: new Date(),},
                adFilCond : "",
                adCondStr : "",
                isAllowFilter : true,
                isAllowBack : false,
                allowWaFilter : true,
                isAllowFilerCampaign : true,
                lists:'',
                columndetails:'',
                reclimit : 0,
                totalCalllogsCount : 0,
                listTableCurPage : 1,
                tableperPage : this.rowOptions[1].value,
                isMobile: mobile,
                summertCondition: {filterType:'call_logs',dateFilter:this.dateFilter}, 
                isCampaign : false,
        };
  }

  setShowLoaderStatus = async (status = false) => {
        this.setState({
            showLoader: status
        })
  }

  componentDidMount = async () => {
    //await this.setShowLoaderStatus(true);
    await this.getCallLogs();
    //this.state = { isPlaying: false}
    //await this.setShowLoaderStatus();
  };

  componentWillUnmount = async () => { 
    const { audioStates } = this.state;
    Object.keys(audioStates).forEach((index) => {
      if (audioStates[index]?.isPlaying) {
        audioStates[index].audio.pause();
      }
    });
  }

  prepareFilterParam = async (cond = "") => {
    let fil_cond = this.props.data.match.params.fil_cond !== undefined && this.props.data.match.params.fil_cond !== "" ? this.props.data.match.params.fil_cond : "";
    let fil_str = "";
    let allowFilter = true;
    let allowBack = false;
    let allowWaFilter = true;
    let allowcampaign = true;
    let isCampaign = false;
    if(fil_cond !== ""){
        fil_cond = parseJSON(base64_decode(fil_cond));
        allowBack = true;
        if(fil_cond["filterType"] !== "campaign" && fil_cond["filterType"] !== "automation") {
            allowFilter = false;
            fil_str = fil_cond["filterValue"];
            let dateStr = fil_str["date"] ? fil_str["date"] : "";
            if(dateStr !== "" && dateStr["noofdays"] !== 1){
                let startDateTime = dateStr["Start_DateTime"]?.[0] || "";
                let endDateTime = dateStr["Start_DateTime"]?.[1] || "";
                startDateTime = formatDateTime(startDateTime,"MMM dd, yyyy");
                endDateTime = formatDateTime(endDateTime,"MMM dd, yyyy");
                dateStr = startDateTime + " - " + endDateTime;
            }else {
                dateStr = "Today";
            }
            const parts = [
                fil_str["agentName"] && fil_str["agentName"],
                fil_str["header"] && fil_str["header"],
                dateStr && dateStr,
            ].filter(Boolean);
            fil_str = parts.join(" | ");
        }else {
            allowcampaign = false;
            allowWaFilter = false;
            isCampaign = true;
            let date = this.state.dateFilter ? this.state.dateFilter : this.dateFilter;
            fil_cond["filterValue"]["date"] = date;
            fil_cond["filterValue"]["status"] = this.state.statusFilter; 
            if(fil_cond["filterType"] === "campaign" || fil_cond["filterType"] === "automation") {
              fil_str = capitalizeFirstLetter(fil_cond["filterType"]) + " -> "+ fil_cond['name'];
            }
        }
    }else{
        let date = this.state.dateFilter ? this.state.dateFilter : this.dateFilter;
        let cond = {
            date : date,
            status : this.state.statusFilter ? this.state.statusFilter : "",
            whatsapp : this.state.outboundFilter ? this.state.outboundFilter : "",
        }
        let param = {
            filterType : "call_logs",
            filterValue : cond,
        }
        fil_cond = param;
    }

    this.setState({
        adFilCond : fil_cond,
        adCondStr : fil_str,
        isAllowFilter : allowFilter,
        isAllowFilerCampaign:allowcampaign,
        isAllowBack : allowBack,
        allowWaFilter : allowWaFilter,
        isCampaign :isCampaign
    })
}
  

    getCallLogs = async (searchStr = "", cond = "") => {
        if(searchStr !== "" || cond !== ""){
          this.setState({
            reclimit : 0,
            totalCalllogsCount : 0,
            listTableCurPage : 1,
            tableperPage : this.rowOptions[1].value
          });
        }
        this.setState({
            dateFilter: cond.date ? cond.date : this.state.dateFilter,
            outboundFilter : cond.outbound ? cond.outbound : this.state.outboundFilter,
            statusFilter : cond.status ? cond.status : this.state.statusFilter ,
            searchStr:searchStr,
            data : [],
            showLoader : true,
        },async () => {
            await this.prepareFilterParam(cond);
            if(cond !== "" && this.state.adFilCond ?.["filterType"] !== "campaign" && this.state.adFilCond ?.["filterType"] !== "automation"){
                let fType = "call_logs";
                let param = {
                    filterType :fType,
                    filterValue : cond,
                }
                //this.state.adFilCond = param;
                this.setState({
                    adFilCond: param,
                    summertCondition: param,
                });
            }
            let params = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "GET_CALL_LOGS",
                SearchStr: searchStr,
                FILTER_COND: this.state.adFilCond, // Use updated state here
                LIMIT : this.state.tableperPage,
                OFFSET : this.state.reclimit
            };
            try {
                let formresponse = await requestApiService.processServerRequest(params, "WebV2");
                if (formresponse.issuccess) {
                    let data = formresponse.data;
                    let lists = data[0] ?? [];
                    let columndetails = data[1] ?? [];
                    let recCount = data[2] ?? 0;
                    this.setState({
                      lists:lists,
                      columndetails:columndetails,
                      totalCalllogsCount : recCount, 
                    })
                    await this.prepareListData(lists, columndetails);
                }
            } catch (error) {
                console.error("Error processing server request:", error);
            }
        });
    
    }


  prepareListData = async (formData, listFields) => {
    const { audioStates } = this.state;
    let data = [];
    let columns = [];
    let tableData = await prepareCustomTableData( formData, listFields, false, false, this.onViewAccountSettings);
    data = tableData.data;
    columns = tableData.columns;
    data = data.map((item, index) => {
      
      return {
        ...item,
        id: item.id || `row-${index}`,
        Call_Cost: item.Call_Cost !== "" ? this.props.currency + item.Call_Cost : this.props.currency+"0.00", // Use string for HTML icon
        Goal_Achievement:
          item.Goal_Achievement === "Yes" ? "Complete" : "Incomplete",
        Durationsec: item.Durationsec !== "" ? formatTime(item.Durationsec) : "00:00:00",
      };
    });

    columns.push({
      name: "Play Audio",
      selector: "access",
      minWidth: "95px !important",
      maxWidth: "0% !important",
      faAlignJustify: "center",
      cell: (row) => (
        <div
          onClick={(event) =>
            this.playList(row["data_row"]["Record_url"], row["id"], event)
          }
          className={`${row["data_row"]["Durationsec"] > 0 ? 'audio-play-button' : 'hide'}`}
        >
          {audioStates[row["id"]]?.isPlaying ? (
            <i className="icon-color fi fi-rr-pause"></i> // Pause icon
          ) : (
            <i className="icon-color le-form-row fi fi-rr-play"></i> // Play icon
          )}
        </div>
      ),
      style: {
        padding: 0,
        margin: 'center',
        justifyContent: 'center',
      }
    }
  );
  this.setState({
    data, columns, showLoader: false,
  });
  }
  setTotalcount = (Count) =>{
    this.setState({
      totalCount:Count
    });
  }

  onClickExports = async () => {
    let confirm = await confirmBox(translateContent("vm.export.confirm.title"), translateContent("vm.export.confirm.msg"), exportIcon, 'Export', 'Cancel');
    if (!confirm) {
        return;
    }
    let { totalCalllogsCount, exportLimit, summertCondition } = this.state;
    if (parseInt(totalCalllogsCount, 10) === 0) {
      showToast("No data found ", "top-right", "error");
    } else if (totalCalllogsCount <= 1000) {
      this.setState({
        showLoader: true,
      });
      let filename = "Call Details";
      await exportFormData("table_37", "", filename, exportLimit,0,summertCondition);
      this.setState({
        showLoader: false,
      });
    } else {
      this.handleExportChanges();
      showToast("A maximum of 5000 records can be download", "top-right", "error");
    }
  };

  handleExportChanges = (name = "modal", e) => {
    if (name === "records") {
      let value = e.value;
      this.setState({
        exportLimit: value,
      });
    } else {
      this.setState((prevState) => ({
        isModalOpen: !prevState.isModalOpen,
        exportLimit: this.exportRecordsCounts[0].value,
      }));
    }
  };

  closeModal = (name) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      showButtonLoading: false,
    }));
  };

  playList = async (audioUrl, rowIndex, event) => {
    const { audioStates, lists, columndetails} = this.state;
    if (audioUrl != undefined && audioUrl != null && audioUrl != "") {
      audioUrl = audioUrl.split(",");
      audioUrl = audioUrl[0];
      event.stopPropagation();

      // Pause all currently playing audios
      Object.keys(audioStates).forEach((index) => {
        if (audioStates[index]?.isPlaying) {
          audioStates[index].audio.pause();
          audioStates[index].audio.currentTime = 0; // Reset playback to the start
        }
        //this.prepareListData(lists, columndetails);
      });

      // If the current row's audio is already playing, toggle it off
      if (audioStates[rowIndex]?.isPlaying) {
        this.setState((prevState) => ({
          audioStates: {
            ...prevState.audioStates,
            [rowIndex]: { isPlaying: false, audio: null },
          },
        }));
        await new Promise((resolve) => this.setState({}, resolve));
        // Call the prepareListData function
        await this.prepareListData(lists, columndetails);
      } else {
        // Play the new audio
         const audio = new Audio(audioUrl);
        await this.prepareListData(lists, columndetails);
        if(audio){
          this.setState({
            audioStates: {
              [rowIndex]: { isPlaying: true, audio }, // Set only the current row as playing
            },
          });
          await this.prepareListData(lists, columndetails);
        }
        audio.play().catch((error) => {
          console.error("Error playing audio:", error);
        } );
 
      
       // Reset state when audio ends
        audio.onended = () => {
          this.setState((prevState) => ({
            audioStates: {
              ...prevState.audioStates,
              [rowIndex]: { isPlaying: false, audio: null },
            },
          }));
          this.prepareListData(lists, columndetails);
        };

        // Update state to reflect the new playing audio
        this.setState({
          audioStates: {
            [rowIndex]: { isPlaying: true, audio }, // Set only the current row as playing
          },
        });
        this.prepareListData(lists, columndetails);
      }
    }
    if (audioUrl === undefined || audioUrl === null || audioUrl === "") {
      showToast("No Audio URL Found.");
      Object.keys(audioStates).forEach((index) => {
        if (audioStates[index]?.isPlaying) {
          audioStates[index].audio.pause();
          audioStates[index].audio.currentTime = 0; // Reset playback to the start
        }
      });
      this.setState({
        audioStates: { isPlaying: false, audio: null },
      });
      this.prepareListData(lists, columndetails);
    }
  };

  handleExportClick = async (index, filename, start, exportLimit, summertCondition) => {
    if(index < 0){
        return;
    }
    this.setState({
        showLoader : true,
        isDisabled : true,
        showButtonLoading: true,
    }) 
    await exportFormData('table_37','', filename, exportLimit, start - 1, summertCondition);
    this.setState({
        showLoader : false,
        isDisabled : false,
        showButtonLoading: false,
    })
}
  
    backAction = () => {
        window.history.back();
    }

    handlePageChange = async (offset,page,perPage) => {
        await stopAudio(this.state.audioStates);
        this.setState({
            reclimit : offset,
            listTableCurPage : page,
            tableperPage : perPage,
            audioStates: { isPlaying: false, audio: null },
          },async () => {
            this.getCallLogs();
        });
    };

    getModalContent = () => {
        let { isModalOpen, totalCalllogsCount, exportLimit, showButtonLoading ,exportRecordsCounts,summertCondition} = this.state;
        let noofloops = Math.ceil(totalCalllogsCount / exportLimit);
        let downloadList = [];
        let startcount = 1;

        for (let i = 0; i < noofloops; i++) {
        let endcount = Math.min(startcount + exportLimit - 1, totalCalllogsCount); // Ensure endcount does not exceed totalCount
        downloadList.push([startcount, endcount]);
        startcount = endcount + 1;
        }
        return (
            <>
            { totalCalllogsCount && exportLimit &&
                <Modal isOpen={isModalOpen} toggle={this.handleExportChanges}>
                    <ModalHeader toggle={this.closeModal}>
                            <div>
                                <div className={`quickreply-inbox-header`}>
                                    <span>Export as CSV</span>
                                    {false && <Select
                                        onChange={(eve) => {
                                            this.handleExportChanges("records", eve)
                                        }}
                                        defaultValue={exportRecordsCounts[1]}
                                        options={exportRecordsCounts}
                                        className={"basic-multi-select col-md-4 mR10"}
                                        classNamePrefix={"le-select"}
                                        isClearable={false}
                                        isDisabled={this.isDisabled}
                                    />}
                                </div>
                            </div>
                    </ModalHeader>
                    <ModalBody>
                    <div>
                            {
                                downloadList.length > 0 && 
                                downloadList.map((data, index) => {
                                    let label = data[0] + " to " + data[1];
                                    let filename = "Call Logs Details " + data[0] + " to " + data[1] + ".csv";
                                    let name ="Download <strong>" + label +  "</strong> records as <strong>" + filename + "</strong>";
                                    return(
                                        <Fragment key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span>{parseHtml(name)}</span>
                                                <span title="Click to Download CSV" className={this.isDisabled ? 'cursor-wait' : 'cursor-p'} onClick={e => this.handleExportClick(this.isDisabled ? -1 : index, filename, data[0], exportLimit, summertCondition)}>
                                                <Loading showLoader={showButtonLoading} mainContent={
                                                <Fragment>
                                                <Button className='theme-bg-g'>
                                                <i class= "fi fi-bs-cloud-download-alt" ></i>
                                                </Button>
                                                </Fragment>
                                                } />
                                                </span>
                                            </div>
                                            <hr />
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </ModalBody>
                </Modal>
            }
            </>
        );
    }

handleSearch = (e) => {
    this.setState({
        searchStr: e
    })
    this.getCallLogs(e);
};

  getTitleBody = (showDetailed) => {
    if (showDetailed) {
      return "";
    }
    return (
     <div></div>
    );
  };



Maincontant = () => {
  const { data, columns, showDetailed, isModalOpen, adCondStr, adFilCond, isAllowFilter, isAllowBack, allowWaFilter, searchStr, isMobile, summertCondition,totalCalllogsCount,listTableCurPage,tableperPage,reclimit,isAllowFilerCampaign,isCampaign,showLoader} = this.state;
  return (
  <Col md={12}>
    <Row className="ms-0 me-0 gx-0 gy-0">
        <Fragment>
            <div>
                { isAllowBack &&
                    <div className='d-flex-alone'>
                        <span className='mt-3 ms-3 group-header'>{adCondStr}</span>
                          <div className='back-action d-flex align-center ms-auto me-3 mt-2'>
                          <a className='cursor-pointer' onClick={(e) => { this.backAction() }}>
                              <span className='d-flex align-center'>
                              <i class="fi fi-br-arrow-left pr-1"></i> <span>Back</span>
                              </span>
                          </a>
                          </div>
                    </div>
                }
                {
                    isAllowFilter && isAllowFilerCampaign && 
                    <Col md={12}>
                    <Filter
                        neeedbottomfilter = {true}         
                        callogFilters={true}
                        guiValidation={isMobile ? 'calllog_mob' : 'calllog_web'}
                        callLogOption1={filterOption1}
                        callLogOption2={filterOption2}
                        datevalue={this.dateFilter}
                        getCallLogs={this.getCallLogs}
                        onClickExports={this.onClickExports}
                        isCSVDownload = {false}
                        onSearch = {this.handleSearch}
                        isAllowWaFilter = {allowWaFilter}
                    /> 
                    </Col> 
                }
            
            </div>
            {
                isAllowFilter &&  (isCampaign ? (!showLoader) : true) && 
                <div>
                    <Col md={12} className="z-0">
                        <CardBody>
                            <CallLogAnalysisSummary 
                                dateFilter={this.dateFilter}
                                searchStr={searchStr}
                                adFilCond={adFilCond}
                                totalCount={totalCalllogsCount}
                                lg={2}
                                adFilter={summertCondition}
                            />
                        </CardBody>
                    </Col>
                </div>
            }
            
            <Col md={12} style={{zIndex:0}}>
                <div className="data-container-contacts">
                    {!showDetailed && (
                        <div  className="data-container">
                            {!this.state.showLoader ? ( data.length === 0 ? ( <NoRecordFound img={aiData} isOnlyContent={true} content={"No Users Found."}/>
                            ) : (
                                <>
                                    {false && <div className="me-3 mt-n1 d-flex ms-auto ff-row-reverse">
                                        <Button className="btn btn-secondary theme-bg-g btn-hover-shine " onClick={e => this.onClickExports()}>
                                            {false && <i className="fi fi-br-search me-2"></i>} Downloaded as CSV
                                        </Button>
                                    </div>}
                                    <div className='me-3 mt-n1 d-flex ms-auto ff-row-reverse cursor-pointer'>
                                        <a className='call-logs-csv' onClick={e => this.onClickExports()}> Download as CSV </a>
                                    </div>
                                    {!isMobile &&
                                      <CustomListTable
                                        data={data}
                                        columns={columns}
                                        style={{}}
                                        isAllowLoadmore = {true}
                                        handleChange = {this.handlePageChange}
                                        totalRows = {totalCalllogsCount}
                                        tblCurPage = {listTableCurPage}
                                        recordsPerPage = {tableperPage}
                                        rowOptions = {this.rowOptions}
                                        offSet = {reclimit}
                                        isMobile = {isMobile}
                                    />                                    }
                                    {isMobile && 
                                    <div className="subaccount-list">
                                      <Card className={"main-card mb-3"}>
                                         
                                          <div class="w-100">
                                            <CustomListTable
                                                data={data}
                                                columns={columns}
                                                style={{}}
                                                isAllowLoadmore = {true}
                                                handleChange = {this.handlePageChange}
                                                totalRows = {totalCalllogsCount}
                                                tblCurPage = {listTableCurPage}
                                                recordsPerPage = {tableperPage}
                                                rowOptions = {this.rowOptions}
                                                offSet = {reclimit}
                                                isMobile = {isMobile}
                                            />
                                          </div>
                                      </Card> 
                                      </div>
                                    }
                                </>)
                            ) : (<Loading showLoader={this.state.showLoader} />)}
                        </div>
                    )}
                </div>
                {isModalOpen && this.getModalContent()}  
            </Col>            
        </Fragment> 
    </Row>
  </Col>
  );
}


  render() {
    const { totalCount, data,totalCalllogsCount,isCampaign,showLoader } = this.state;
    return (
      <Fragment>
        <Common
          showLoader={isCampaign && (!showLoader)}
          title={"Call Details"}
          titleContent={<span>{"Call Details"}</span>} //{/* <span class="count">{totalCalllogsCount}</span> */} // {/* {this.props.match.params.fil_cond !== undefined ? data.length : totalCount */}
          titleBody={this.getTitleBody()}
          mainContent={<div className="w-100">{this.Maincontant()}</div>}
          titleKey={"call_logs_enabled"}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
  setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLog);

