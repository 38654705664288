import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { Container, Button } from "reactstrap";
import invoice from "./assets/images/invoice.png";
import { useStepsContext } from "./context";
import {getCurrentPlanInfo,getPlanPricing} from '../../Common/Appcommon';

const SubscriptionConfirm = () => {
    const { isUsage, setCurrentStep, isAgency, setCurrentPlan, processPlansList } = useStepsContext();

    const handlePaymentConfirmPage = async () => {
        let currentPlan = await getCurrentPlanInfo(isAgency);
        let plan = await getPlanPricing(isAgency);
        setCurrentPlan(currentPlan);
        if(isUsage){
            await processPlansList(plan, currentPlan);
            setCurrentStep(6);
        }else{
            let url = "#/usage";
            if(isAgency){
                url = "#/plan-billing"
            }
            window.location.hash = url;
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <div className="no-records-container">
                    <div className="d-grid align-center">
                        {/* <i class="fi fi-ss-badge-check success-tick-icon"></i> */}
                        <img src={invoice} className="w-25" alt="Stripe Logo"></img>
                        <div className="mt-3 font-color">
                            <span className="h3">Success!</span>
                            <br/>
                            <span> Your subscription has been successfully updated.</span>
                        </div>
                        <Button className="theme-bg-g mt-3 payment-scs-btn" style={{"margin": "auto"}} onClick={e => handlePaymentConfirmPage()}>
                            Go to Plan details
                        </Button>
                    </div>
                </div>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionConfirm);