import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import requestApi from "../../services/requestApi.service";
import ListTable from "../../Common/ListTable";
import { Container } from "reactstrap";
import { prepareCustomTableData, formatDateTime, isAgentAccount, getCurrencySymbol } from "../../Common/Appcommon";
import NoRecordFound from '../Common/NoRecordFound';
import aiData from "../../assets/utils/images/agent_no_records.png";
import { useTheme } from "../../Context/ThemeContext";

const Payments = (props) => {
    const { searchStr, dateFilter, isAgent } = props;  // Destructure props to get the search and date filter values
    const [columns, setColumns] = useState([]);
    const [formData, setFormData] = useState([]);
    const { subCurrency, agencyCurrency } = useTheme();
    useEffect(() => {
        (async () => {
            await getFormData(); // Call your async function
        })(); // This is the proper syntax for an IIFE
    }, [searchStr, dateFilter]);    

    const processCustomerPaymentHistory = (tableData) => {
        let columns = tableData.columns ?? [];
        let data = tableData.data ?? [];
        data = data.map((fd, index) => {
            let amount = parseInt(fd['amount']);
            let symbol = fd.data_row.currency;
            let CurrencySymbol = getCurrencySymbol(symbol)
            amount = amount / 100;
            fd["amount"] = (
                <div>
                    {isAgent ? agencyCurrency + amount.toFixed(2) : CurrencySymbol + amount.toFixed(2)}
                </div>
            );

            let status = fd['paymentstatus'];
            let className = "";
            if (status === "succeeded") {
                status = "Paid";
                className = "text-success"; // Green for success
            } else if (status === "failed") {
                status = "Failed";
                className = "text-danger"; // Red for failure
            } else if (status === "refunded") {
                status = "Refunded";
                className = "text-info"; // Blue for refunded
            } else {
                status = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
                className = "text-warning"; // Yellow for pending or other statuses
            }
            let cDate = fd['createdOn'];
            if(cDate !== "" && cDate !=null && cDate !== "undefined"){
                cDate = formatDateTime(new Date(cDate),"MMM d, yyyy");
                fd['createdOn'] = cDate;
            }
            fd['paymentstatus'] = (
                <div className={className}>
                    {status}
                </div>
            );

            return fd;
        });
        
        let s_no = [{
            name: (
                <div>
                    S.No
                </div>
            ),
            selector: (row, index) => index + 1,
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "60px !important",
        }];
        
        columns = [...s_no, ...tableData.columns];

        columns = columns.map((value, index) => {
            if(value["name"] === "Amount"){
                value["width"] = '150px !important';
            }else if(value["name"] === "Payment ID"){
                //value["width"] = '270px !important';
            }else if(value["name"] === "Date"){
                value["width"] = '120px !important';
            }else if(value["name"] === "Status"){
                value["width"] = '90px !important';
            }
            
            return value
        })
        columns.push(
            {
                name: "Invoice",
                selector: 'invoice',
                width: '110px !important',
                cell: (row) => {
                    let orderid = row.data_row && row.data_row.orderid ? row.data_row.orderid : "";
                    let status = row.data_row && row.data_row.paymentstatus ? row.data_row.paymentstatus : "";
                    if(status !== "succeeded"){
                        return(
                            <div></div>
                        )
                    }
                    return (
                        <div className="payment-list-box">
                            <a className = "cursor-pointer text-d-underline font-bold theme-c-link" onClick={e => downloadInvoise(orderid)}>Download</a>
                        </div>
                    )
                },
            },
        );
        return {columns: columns, data: data}
    }

    const getFormData = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_PAYMENT_HISTORY",
            isOtherApiReq: true,
            searchStr: searchStr,
            dateFilter: dateFilter
        };

        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let res = response.data;
                let formData = res[0] ?? [];
                let listFields = res[1] ?? [];
                let tableData = await prepareCustomTableData(formData, listFields, false, false);
                if(Object.keys(tableData).length > 0){
                    let {columns, data} = processCustomerPaymentHistory(tableData);
                    if(columns !== undefined && columns.length > 0){
                        setColumns(columns);
                    }
                    if(data !== undefined && data.length > 0){
                        setFormData(data);
                    }else if(data === undefined || data.length <= 0){
                        setFormData([]);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching payment history:", error);
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const downloadInvoise = async (orderid) => {
        const params = {
            "ENTITY": "STRIPE_PROCESSOR",
            "OPERATION": "GET_INVOICE",
            "orderid": orderid,
            "isAgency": isAgentAccount(),
            "iswpapp" : true
        };

        let response = await requestApi.processServerRequest(params,'WebV2','post');
        if(response.issuccess){
            if(response.data && response.data !== ""){
                openInNewTab(response.data);
            }
        }
    }

    return (
        <Fragment>
            <Container>
                {isAgent && formData.length <= 0 ? ( <NoRecordFound img={aiData} isOnlyContent={true} content={"No Users Found."}/> ) : (<ListTable columns={columns} data={formData} />)}  
            </Container>
        </Fragment>
    );    
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Payments);