import React, { Fragment } from "react";
import { setAppCustomTitle } from "../../../reducers/ThemeOptions";
import { connect } from "react-redux";
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Input, Row, Col, Card, CardBody, InputGroup
} from 'reactstrap';
import Select from "react-select";
import requestApi from '../../../services/requestApi.service';
import { parseJSON, showToast, maskKey, confirmBox, setSessionValue, translateContent,getWarningIcon } from '../../../Common/Appcommon';
import Loading from "../../../Common/Loading";
import TooltipItem from "../../../Common/ToolTip";

class Stripe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publishableKey: '',
            secretKey: '',
            displaySecret: '',
            displayPublic: '',
            isValid: false,
            account_id : '',
            confirm:false,
            modal: false,
            errors: {},
            showLoading:false,
            currencyList: [],
            selectCurrency: {},
            displayCurrency: {},
            allowEditCurrency: false,
            setSubCurrency:{},
        };
    }

    componentDidMount = async () => {
        await this.getStripeDetails();
    };

    toggle = () => {
        this.setState(prevState => ({ modal: !prevState.modal, errors: {},publishableKey : "",secretKey:"" ,confirm:false}));
        if(this.state.modal){
            this.setState({
                selectCurrency: this.state.displayCurrency
            })
        }
    };
    setSubCurrency(newCurrency) {
        let cueerncyData = {
            subaccount : newCurrency
        }
        this.props.setSubCurrency(cueerncyData);
    }

    getStripeDetails = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_STRIPE_DETAILS",
            METHOD: "GETALL",
        };
        const response = await requestApi.processServerRequest(params, "WebV2");
        if (response.issuccess && response.data.length > 0) {
            const formResponse = response.data[0];
            let publickey = "";
            let secretKey = "";
            let account_id = "";
            let currencyList = formResponse["currency_list"];
            let currency = formResponse["currency"];
            let allowEditCurrency = true;
            currency = parseJSON(currency);

            if(!currency || Object.keys(currency).length === 0){
                currency = currencyList && currencyList.length > 0 ? currencyList[0] : {};
            }else{
                allowEditCurrency = false;
            }

            if(formResponse["publishable_key"]){
                publickey = parseJSON(atob(formResponse["publishable_key"]));
                publickey = maskKey(publickey);
            }
            if(formResponse["secret_key"]){
                secretKey = parseJSON(atob(formResponse["secret_key"]));
                secretKey = maskKey(secretKey);
            }
            if(formResponse["account_id"]){
                account_id = formResponse["account_id"];
            }
            this.setState({
                account_id: account_id,
                isValid: account_id && publickey && secretKey,
                displayPublic: publickey,
                displaySecret: secretKey,
                currencyList: currencyList,
                displayCurrency: currency,
                selectCurrency: currency,
                allowEditCurrency: allowEditCurrency
            });
        }
    };


    setStripeData = async (method_callback) => {
        const { account_id, publishableKey, secretKey, selectCurrency, confirm, displayCurrency } = this.state;
        const pattern = /^pk_(test|live)_[a-zA-Z0-9]{24,}$/;
        // Basic validation
        const errors = {};
        if (!publishableKey) errors.publishableKey = "Publishable key is required.";
        if (!pattern.test(publishableKey)) errors.publishableKey = "Invalid publishable key format.";
        if (!secretKey) errors.secretKey = "Secret key is required.";
        if (Object.keys(selectCurrency).length === 0) errors.selectCurrency = "Currency is required.";

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return; // Exit if there are errors
        }

        if(method_callback !== "check" && publishableKey.startsWith("pk_test") || publishableKey.startsWith("sk_test")){
            const userConfirmed = await confirmBox(
                "Test Mode",
                translateContent("vm.agency.stripe.test.mode.warning"),
                getWarningIcon(),
                'Continue',
                'Cancel'
            );
            if (!userConfirmed) {
                return;
            }
        }
        if(method_callback !== "check" && displayCurrency.value !== selectCurrency.value && account_id !== ""){
            let currencyArr = selectCurrency.label.split("- ");
            let currecyValue = currencyArr[0] + "( "+currencyArr[1]+" )";
            const userConfirmed = await confirmBox(
                "Warning!",
                translateContent("vm.agency.stripe.update.currency",{newCurrency: currecyValue}),
                getWarningIcon(),
                'Update Currency',
                'Cancel'
            );
            if (!userConfirmed) {
                return;
            }
        }
        
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_STRIPE_DETAILS",
            accountid: account_id,
            publicKey: publishableKey,
            secretKey: secretKey,
            currency: selectCurrency,
            confirm: confirm,
        };
        this.setState({showLoading:true});
        const response = await requestApi.processServerRequest(params, "WebV2");

        if (response.issuccess) {
            this.setSubCurrency(selectCurrency);
            setSessionValue('subAccountCurrency', JSON.stringify(selectCurrency));
            showToast(translateContent("vm.agency.save.success"));
            await this.getStripeDetails();
        } else {
            if (!confirm && response.errormsg === "Changing Stripe Details") {
                const userConfirmed = await confirmBox(
                    translateContent("vm.agency.stripe.confirm.msg"),
                    translateContent("vm.agency.confirm.msg"),
                    getWarningIcon(),
                    'Update',
                    'Cancel'
                );
                if (!userConfirmed) {
                    this.setState({showLoading:false});
                    return;
                }
                this.setState({ confirm: true }, this.setStripeData("check"));
                return;
            }
            // Handle other errors
            showToast(response.errormsg || translateContent("vm.agency.error"), 'top-right', 'error');
        }
        this.setState({showLoading:false});
        this.toggle();
    };

    handleChange = (e, name) => {
        if(name === "currency"){
            this.setState({
                selectCurrency: e
            })
        }else{
            const { id, value } = e.target;
            this.setState({ [id]: value, errors: { ...this.state.errors, [id]: null } });
        }
    };


    getModalContent = () => {
        const { publishableKey, secretKey, errors, currencyList, selectCurrency, allowEditCurrency } = this.state;

        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className="heading">
                    Connect with Stripe
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <InputGroup>
                            <Label>Publishable Key</Label>
                            <div className='pl-2'>
                                <TooltipItem id={"1"} text={"The key that identifies your account for payments."} content={<i class="fi fi-ss-info"></i>} />
                            </div>
                        </InputGroup>
                        <Input
                            type="text"
                            id="publishableKey"
                            value={publishableKey}
                            onChange={e => this.handleChange(e, "publishableKey")}
                            placeholder="Enter Publishable Key"
                            required
                            className={errors.publishableKey ? 'is-invalid' : ''}
                            autoComplete="off"
                        />
                        {errors.publishableKey && <div className="invalid-feedback">{errors.publishableKey}</div>}
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <Label>Secret Key</Label>
                            <div className='pl-2'>
                                    <TooltipItem id={"2"} text={"Your secret key for Stripe integration and secure transactions."} content={<i class="fi fi-ss-info"></i>} />
                                </div>
                            </InputGroup>
                        <Input
                            type="text"
                            id="secretKey"
                            value={secretKey}
                            onChange={e => this.handleChange(e, "secretKey")}
                            placeholder="Enter Secret Key"
                            required
                            className={errors.secretKey ? 'is-invalid' : ''}
                            autoComplete="off"
                        />
                        {errors.secretKey && <div className="invalid-feedback">{errors.secretKey}</div>}
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <Label>Choose Currency</Label>
                            <div className='pl-2'>
                                    <TooltipItem id={"3"} text={"Choose the currency type for your customer's transactions."} content={<i class="fi fi-ss-info"></i>} />
                                </div>
                        </InputGroup>
                        <Select
                            name="currency"
                            options={currencyList}
                            value={selectCurrency}
                            onChange={e => this.handleChange(e, "currency")}
                            placeholder="Enter Secret Key"
                            required
                            autoComplete="off"
                            classNamePrefix="wa-select"
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Loading showLoader={this.state.showLoading} mainContent={
                        <Button className="theme-bg-g" onClick={this.setStripeData}>
                            Confirm Stripe Account
                        </Button>
                    }
                    />
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const { isValid, modal, displayPublic, displaySecret, displayCurrency } = this.state;

        return (
            <Fragment>
                <div className="d-container container m-auto max-width-65">
                    <div className="agency-details w-100">
                        <Card className='card-br'>
                            <CardBody className='pb-6'>
                                <Row className="dns-row">
                                    <Col md={9}>
                                        <span className="heading">Stripe</span>
                                        <span className="header-desc ml-5">
                                            Connect your Stripe account to start accepting payments.
                                        </span>
                                    </Col>
                                    <Col md={3}>
                                        <Button className="theme-bg-g" onClick={this.toggle}>
                                            {!isValid ? ('Connect with Stripe') : ('Modify Stripe Connection')}
                                        </Button>
                                    </Col>
                                </Row>

                                {isValid && (
                                    <div className="card-fields d-grid">
                                        <div className="cs-domain">
                                            <Row className="g-0s mt-2">
                                                <Col md={10}>
                                                    <Label for="Publickey" className="header-desc">Publishable Key</Label>
                                                    <Input
                                                        type="text"
                                                        id="Publickey"
                                                        value={displayPublic}
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="w-auto mt-4">
                                                <Col md={10}  >
                                                    <Label for="Secretkey" className="header-desc">Secret Key</Label>
                                                    <Input
                                                        type="text"
                                                        id="Secretkey"
                                                        value={displaySecret}
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="w-auto mt-4">
                                                <Col md={10}  >
                                                    <Label for="currency" className="header-desc">Currency</Label>
                                                    <Select
                                                        type="text"
                                                        id="currency"
                                                        value={displayCurrency}
                                                        isDisabled
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )}
                                {modal && this.getModalContent()}

                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stripe);
