import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from "react-perfect-scrollbar";

import Nav from "../AppNav/VerticalNavWrapper";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import BottomNav from "../AppNav/VerticalSettingsMenu";
import VerticalSubAccountList from "../AppNav/VerticalSubAccountList";
//import { isMobile } from "../../Common/Appcommon";
import { isMobile,isAgentAccount } from "../../Common/Appcommon";

class AppSidebar extends Component {
  state = {
    items: [
      { id: 1, text: 'Buy eggs' },
    ]
  };

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    let {
      backgroundColor,
      enableBackgroundImage,
      enableSidebarShadow,
      backgroundImage,
      backgroundImageOpacity
    } = this.props;

    let isAgency = false;//isAgentAccount();
    return (
      <Fragment>
        <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
        <TransitionGroup>
          <CSSTransition component="div"
            className={cx("app-sidebar", backgroundColor, {
              "sidebar-shadow": enableSidebarShadow, 
              "z-high" : isMobile(),
            })}
            appear={true} enter={false} exit={false} timeout={500}>
            <div>
              <PerfectScrollbar>
               {/* <div className="app-sidebar__inner">*/}
                   <div className={`${isAgentAccount() ? "" : 'sidebar-top-adjust'} app-sidebar__inner`}>

                  <VerticalSubAccountList />
                  {
                    isAgency && <div className="agency-badge">
                      <span>Agency View</span>
                    </div>
                  }
                  <Nav />
                  <BottomNav />
                </div>
              </PerfectScrollbar>
              <div className={cx("app-sidebar-bg", backgroundImageOpacity)}
                style={{
                  backgroundImage: enableBackgroundImage
                    ? "url(" + backgroundImage + ")"
                    : null,
                }}>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
