import React, { Fragment, useEffect, useState } from "react";
import gCalendar from "../../assets/utils/images/Intagration image/google_calendar.svg"
import cx from "classnames";
import { Button, Row, Col } from "reactstrap";
import requestApiService from "../../services/requestApi.service";
import Tabs from "react-responsive-tabs";

const GoogleCalendar = (props) => {
    let {install,installedButton,name} = props;
    const [isConnected, setConnectionStatus] = useState(false);
    const [integrationId,setintegrationId] = useState('');
    //let [integrationData, setIntegrationData] = useState([]);
    let integrationData = [];
    const [tabInfo, setTabInfo] = useState([]);
    useEffect(() => {
        (async () => {
            await fetchGoogleAccountDetails();
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(async () => {
        if(install === true){
            await onInstallGoogle();
        }
    }, [install]);

    const fetchGoogleAccountDetails = async () => {
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "LOAD_INTEGRATIONS",
            TYPE: name
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let integrationInfo = response.data;
            if (integrationInfo.length > 0) {
                setConnectionStatus(true);
                //setIntegrationData(integrationInfo);
                integrationData = integrationInfo;
                let tabs = [
                    {
                        name: "Accounts",
                        biography: <Fragment>{getAccountView()}</Fragment>,
                    },
                    {
                        name: "Info",
                        biography: <Fragment>{getGeneralInfoView()}</Fragment>,
                    },
                ];
                setTabInfo(tabs);
            } else {
                integrationData = [];
                setConnectionStatus(false);
                setTabInfo([]);
            }
        }
    };


    const onInstallGoogle = async (e) => {
        let appHost = window.location.hostname;
        let protocol = window.location.protocol;
        let callback = protocol + "//" + appHost + "/oauth/callback";
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_OAUTH_URL",
            NAME: "GOOGLE_CALENDAR",
            CALLBACK: callback
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let authUrl = response.data;
            window.parent.location.href = authUrl;
        }
    }

    const removeIntegration = async(e, integrationId) => {
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "REMOVE_INTEGRATION",
            ID: integrationId
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            installedButton();
            setintegrationId('');
            await fetchGoogleAccountDetails();
        }
    }

    const getSimpleTabs = () => {
        return tabInfo.map(({ name, biography }, index) => ({
            key: index,
            title: name,
            getContent: () => biography, // Render function if it's a component
        }));
    }

    const getAccountView = () => {
        if (integrationData.length > 0) {
            let googleInfo = integrationData[0];
            let integrationId = googleInfo[0];
            setintegrationId(integrationId);
            let connectedInfo = googleInfo[2];
            connectedInfo = JSON.parse(connectedInfo);
            let name = connectedInfo['name'];
            let email = connectedInfo['email'];
            return (
                <Fragment>
                    <div className="ghl-account-details">
                        <div className="hor-gap">
                            <span className="field-name">{name}</span>
                            <div className="hor-8px">
                                <button onClick={(e) => {removeIntegration(e, integrationId)}} className='btn btn-danger'>
                                    Remove
                                </button>
                            </div>
                        </div>
                        <table>
                            <tr >
                                <td className="field-title">Email Id</td>
                                <td className="field-value">{email}</td>
                            </tr>
                        </table>
                    </div>
                </Fragment>
            )
        }
    }

    const getGeneralInfoView = () => {
        return (
            <Fragment>
                <div>
                    <div className="card-fields">
                        <span className="field-name">Website</span>
                        <span>
                        <a href="https://calendar.google.com" target="_blank">https://calendar.google.com</a>
                        </span>
                    </div>
                    <div className="card-fields">
                        <span className="field-name">Overview</span>
                        <span className="subheading">The OneInbox integration with Google Calendar simplifies scheduling for individuals and businesses by connecting advanced AI calling capabilities to Google’s reliable calendar platform. With this integration, users can automate appointment bookings, check real-time slot availability, and manage their schedules effortlessly. </span>
                    </div>
                    <div className="card-fields">
                        <span className="field-name">Key Benefits</span>
                        <span className="subheading ver-2px">
                            <span className="col-pri"> AI-Powered Scheduling </span>
                            Enable AI agents to book and reschedule appointments by checking your Google Calendar availability, reducing manual effort.
                            <span className="col-pri"> Improved Accessibility </span>
                            Offer round-the-clock booking and scheduling support, ensuring customers can book appointments at any time.
                            <span className="col-pri"> Seamless Synchronization </span>
                            Automatically sync appointments with Google Calendar to keep your schedule up-to-date and eliminate double bookings.
                        </span>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className="agent-details">
                {!isConnected &&
                    <Fragment>
                        <div>
                            <div className="ghl-heading">
                                <div className="ghl-details">
                                <Row>
                                    <Col md={1}>
                                    <span className="card-image-status">
                                        <img src={gCalendar} className="intagration-card-detail-img"/>
                                    </span>
                                    </Col>
                                    <Col md={8} className="p-1">
                                    <span className="heading">
                                        Google Calendar
                                    </span>
                                    <span className="tag">
                                        Calendar
                                    </span>
                                    </Col>
                                    <Col className="d-flex justify-content-center">
                                        <Button
                                            name={"cancel"}
                                            className={cx("badge-theme badge-btn m-4 btn btn-secondary")} // badge-theme badge-btn m-4 btn btn-secondary
                                            onClick={(e) => { onInstallGoogle(e) }}
                                        >
                                            <i class="fi fi-br-plus-small"></i>
                                            <span>{"Install App"}</span>
                                        </Button>
                                    </Col>
                                    </Row>
                                </div>
                            </div>
                            {getGeneralInfoView()}
                        </div>
                    </Fragment>
                }
                {isConnected &&
                    <Fragment>
                        <div>
                            <div className="connected-ghl-heading">
                                <Row>
                                    <Col md={1}>
                                        <span className="card-image-status">
                                            <img src={gCalendar} className="intagration-card-detail-img"/>
                                        </span>
                                        </Col>
                                        <Col md={8} className="p-1">
                                        <span className="heading">
                                            Google Calendar
                                        </span>
                                        <span className="tag">
                                            Calendar
                                        </span>
                                    </Col>
                                    <Col>
                                        <div className="hor-1rem d-flex justify-center">
                                            <Button
                                                name={"cancel"}
                                                className={cx("badge-theme badge-btn p-1")}
                                                onClick={(e) => { removeIntegration(e,integrationId) }}
                                            >
                                                <i class="fi fi-br-plus-small"></i>
                                                <span>{"Uninstall App"}</span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="agent-details-tab calls-details">   
                                <Row md={12}>
                                        <Tabs tabsWrapperClass="body-tabs" activeTab={"1"}
                                            showMore={true}
                                            transform={true}
                                            showInkBar={true}
                                            selectedTabKey={0}
                                            transformWidth={400}
                                            items={getSimpleTabs()} />
                                </Row>
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
    )
}

export default GoogleCalendar;