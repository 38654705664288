import React, {Component, Fragment} from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { encode as base64_encode } from 'base-64';

import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import "./assets/index.css";
import Common from "../Common";
import { Button, Col, Input, Row } from "reactstrap";
import cx from "classnames"
import { filterOptions } from "./assets/Constant";
import { 
    isMobile,
    formatDate,
    confirmBoxWithImage,
    formatDateTime,
    showToast,
    convertUTCToLocalTime,
    getPermissions,parseJSON, handleErrorResponse,
    getCurrentTime,checkIsAdmin,
    translateContent,
    capitalizeFirstLetter,
    getDeleteIcon,
    convertUTCToTimeZone,
} from "../../Common/Appcommon";
import ListTable from "../../Common/ListTable";
import requestApiService from "../../services/requestApi.service";
import outCollImg from "../../assets/utils/images/out_call.png";
import darkoutCollImg from "../../assets/utils/images/dark_out_call.png";
import NoRecordFound from '../Common/NoRecordFound';
import aiData from "../../assets/utils/images/agent_no_records.png";


class OutboundService extends Component {
    formname = "table_83";
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            filterOption: {},
            searchValue: "",
            formData: [],
            columns: [],
            mainLoader: true,
            moduleLimitReached:false,
            isAdmin:false,
        }
    }

    componentDidMount(){
        const {type} = this.state;
        let options = {};
        if(filterOptions && filterOptions[type] && filterOptions[type][0]){
            options = filterOptions[type][0];
        }
        this.setState({
            filterOption: options
        }, () => {
            this.loadOutboundDatas();
        })
    }

    loadOutboundDatas = async () => {
        let { type, filterOption, searchValue } = this.state;
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_OUTBOUND_DETAILS",
            type: type,
            formname: this.formname,
            filterOption: filterOption,
            searchStr: searchValue,
        }
        let response = await requestApiService.processServerRequest(params);
        if(response.issuccess){
            let formData = response.data;
            let isAdmin = await checkIsAdmin();
            this.setState({isAdmin:isAdmin});
            let tableData = this.customTableView(formData);
            this.setState({
                formData: tableData.data,
                columns: tableData.columns,
                mainLoader: false
            })
        }else{
            this.setState({
                mainLoader: false
            })
        }
      

    }

    /**
     * Handles changes in the dropdown select input.
     * 
     * @function handleChange
     * @param {Object} selectedOption - The selected option object from the dropdown.
     * @returns {void}
     */
    handleChange = (selectedOption) => {
        this.setState({ 
            filterOption: selectedOption, 
            mainLoader: true 
        }, () => {
            // Ensure `loadOutboundDatas` is called after state update
            this.loadOutboundDatas();
        });
    };

    /**
     * Handles changes in the search input field.
     * 
     * @function handleSearchChange
     * @param {Object} event - The event object from the input field.
     * @returns {void}
     */
    handleSearchChange = (event) => {
        const searchValue = event.target.value;
        this.setState({ 
            searchValue, 
            mainLoader: true 
        }, () => {
            // Ensure `loadOutboundDatas` is called after state update
            this.loadOutboundDatas();
        });
    }

    onViewAccountAction = (row) => {
        if(row[this.formname + "_id"] && this.state.type){
            let reqObj = {};
            reqObj["filterValue"] = {
                fieldId: row[this.formname + "_id"]
            };
            reqObj['name'] = row['Name'];
            reqObj["filterType"] = this.state.type;
            let req = JSON.stringify(reqObj);
            req = base64_encode(req);
            window.location.hash = `#/call-details/${req}`;
        }
    }

    stopCampaign = async (row, status) => {
        let header = "Stop Campaign";
        let body = "Are you sure you want to stop this campaign?";
        if(status === "Paused"){
            header = "Start Campaign";
            body = "Are you sure you want to start this campaign?";
        }
        let confirm = await confirmBoxWithImage(header, body, document.body.className === 'dark-theme' ?  darkoutCollImg : outCollImg , header);
        if(confirm){
            let idlabel = this.formname + "_id";
            let params = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "STOP_CAMPAIGN",
                type: this.state.type,
                formname: this.formname,
                formid: row[idlabel],
                status: status,
            }
            let response = await requestApiService.processServerRequest(params);
            if(response.issuccess){
                this.loadOutboundDatas();
            }
        }
    }

    deleteRow = async (row) => {
        let outboundType = capitalizeFirstLetter(this.state.type);
        let title = translateContent("vm.outbound.delete.title",{type: outboundType});
        let body = translateContent("vm.outbound.delete.msg",{type: outboundType});
        let confirmbtn = translateContent("vm.outbound.delete.title",{type: outboundType});
        /*if(this.state.type === "campaign"){
            title = "Delete Campaign";
            body = "Are you sure you want to permanently delete this campaign?";
            confirmbtn = "Delete Campaign";
        }*/
        let confirm = await confirmBoxWithImage(title, body, getDeleteIcon() , confirmbtn, 'Cancel');
        if(confirm){
            let idlabel = this.formname + "_id";
            let params = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "DELETE_OUTBOUND_DATA",
                type: this.state.type,
                formname: this.formname,
                formid: row[idlabel],
            }
            let response = await requestApiService.processServerRequest(params);
            if(response.issuccess){
                showToast(translateContent("vm.delete.success"));
                this.loadOutboundDatas();
            }
        }
    }

    customTableView = (tableData) => {
        let mobile = isMobile();
        let data = tableData[0];
        let field = tableData[1][0] ?? [];
        let alias = tableData[1][1] ?? [];
        let column = [];
        let {type,isAdmin} = this.state;
        alias && alias.map((value, index) => {
            let row = {};
            row["name"] = value;
            if(field[index] === "Name"){
                row["cell"] = (row) => {
                    let name = row[field[index]];
                    let status = row["Status"];
                    let updated_username = "";//row["updatedusername"] ?? "";
                    let updatedon = row["updatedon"];
                    let enableEdit = (status === "Draft" && this.state.type === "campaign") || this.state.type === "automation";
                    updatedon = formatDate(updatedon, "mmmm dd, yyyy, hh:MM TT");
                    updated_username = updated_username.toLocaleLowerCase() === 'sadmin' ? "System" : updated_username;
                    updated_username = updated_username ? updated_username + ", " + updatedon : updatedon;
                    updated_username = "Last Update: " + updated_username;
                    return (
                        <div>
                            <div className="mt-2 mb-2">
                                <a 
                                    className={isAdmin && cx('font-bold theme-c-link', {"cursor-pointer text-d-underline theme-c-link" : enableEdit})} 
                                    onClick={isAdmin && enableEdit ? e => this.handleRowClick(row) : () => {}}
                                >
                                    <span className='d-flex align-center'>
                                        <span>{name}</span>
                                    </span>
                                </a>
                            </div>
                            <div className="mb-2 field-desc">
                                {updated_username} 
                            </div>
                        </div>
                    )
                };
                row["width"] = mobile ? "350px" : isAdmin ? '' : "40% !important";
            }
            if((type === "campaign" && field[index] === "Schedule_Time") || (type === "automation" && field[index] === "Start_DateTime")){
                row["cell"] = (row) => {
                    let status = row["Status"];
                    let Work_Hours = (parseJSON(row['Work_Hours']));
                    let timeZone = Work_Hours['time_zone']['value'];
                    timeZone = timeZone.split(")-");
                    timeZone = timeZone[1]
                    let Schedule_Time = "";
                    if((status !== "Draft" && type === "campaign") || (type === "automation") || true){
                        Schedule_Time = row[field[index]];
                        if(Schedule_Time){
                            if(Schedule_Time.indexOf(" ") < 0){
                                Schedule_Time = Schedule_Time + " " + getCurrentTime();
                            }
                            Schedule_Time = formatDate(convertUTCToTimeZone(Schedule_Time,timeZone),"mmm dd, yyyy, hh:MM:ss TT");
                        }
                    }
                    return (
                        <div>
                            <div className="mt-2 mb-2">
                                {Schedule_Time}
                            </div>
                        </div>
                    )
                };
                row["width"] = mobile ? "150px" : isAdmin ? "25% !important" : "30% !important";
            }

            if(field[index] === "Status"){
                row["center"] = true;
                row["cell"] = (row) => {
                    let status = row[field[index]];
                    let classname = "badge-info";
                    if(status === "Scheduled" || status === "Progress"){
                        classname = "badge-warning";
                    } else if (status === "Completed" || status === "Active"){
                        classname = "badge-success";
                    } else if (status === "Paused"){
                        classname = "badge-danger";
                    }
                    return (
                        <div>
                            <div className={"badge " + classname}>
                                {status}
                            </div>
                        </div>
                    )
                };
                row["width"] = mobile ? "60px" : '10% !important';
                row["style"] = {
                    alignItems: "center", // Vertically center content in rows
                    justifyContent: "center", // Horizontally center content
                };
            }

            column.push(row);
        });

        column.push(
            {
                name: "Analytics",
                selector: 'view_log',
                center: true,
                width: mobile ? "50px" : '15% !important',
                cell: (row) => (
                    <div className="m-auto">
                        <Button onClick={() => this.onViewAccountAction(row)} className="access-account-btn theme-bg-g">
                            <span>Analytics</span>
                            <i class="ml-2 fi fi-bs-arrow-up-right-from-square "></i>
                        </Button>
                    </div>
                ),
            },
            isAdmin && {
                name: "Actions",
                center: true,
                width: mobile ? "50px !important" : '10% !important',
                cell: (row) => {
                    let status = row.Status;
                    let isEnabled = false;
                    if(status !== "Completed" && status !== "Active"){
                        isEnabled = true;
                    }
                    if(status === "Progress" || status === "Paused"){
                        return(
                            <div className={"m-auto cursor-pointer"} onClick={e => this.stopCampaign(row, status)}>
                                {
                                    status === "Progress" ? (
                                        <i class="fi fi-rr-pause"></i>
                                    ) : (
                                        <i class="fi fi-rr-play"></i>
                                    )
                                }
                            </div>  
                        )
                    }
                    return (
                        <div className={cx("m-auto", {"disabled" : !isEnabled}, {"cursor-pointer" : isEnabled})} onClick={isEnabled ? () => this.deleteRow(row) : undefined}>
                            <i class="fi fi-rr-trash"></i>
                        </div>
                    )
                }
            },

        );

        return {data: data, columns: column};
    }

    handleRowClick = (row) => {
        let { type,moduleLimitReached } = this.state;
        if(row){
            let formid = row[this.formname + "_id"];
            // Update the URL hash to navigate to the "edit" page for the current type
            window.location.hash = `#/${type}/edit/${formid}`;
        }else{
            // Update the URL hash to navigate to the "new" page for the current type
            window.location.hash = `#/${type}/new`;
        }
    };

    /**
     * Renders the filter container header with search and dropdown filter options.
     * The header adapts based on the type prop, which determines titles and options.
     * 
     * @function getFilterContainer
     * @returns {JSX.Element} A React fragment containing the filter header.
     */
    getFilterContainer = () => {
        const {type, searchValue, filterOption,isAdmin} = this.state;

        // Set dynamic titles and button labels based on the type prop
        let title = "Campaigns";
        let titlebtnLabel = "New Campaign";
        let options = filterOptions[type];

        if (type === "automation") {
            title = "Automations";
            titlebtnLabel = "New Automation";
        }
        // Render the filter container
        return (
            <div className={cx("body-header p-3 ", {"body-header-web" : isAdmin && !isMobile()})}>
                <Row md={12}>
                    { 
                        isMobile() ? (
                            <Fragment>
                                <Col md={3} className="mt-5">
                                    <span className="outbound-title w-50">
                                        {title}
                                    </span>
                                </Col>
                                <Col md={3} className="mt-3 pr-0">
                                    <div className="le-form-row">
                                        <div className="input-with-icon">
                                        <span className="search-icon pe-2">
                                            <i className="icon-color fi fi-rs-search"></i>
                                        </span>
                                        <Input
                                            type="text"
                                            onChange={this.handleSearchChange}
                                            value={searchValue}
                                            placeholder="Search by Name"
                                            className="ps-4"
                                            autoComplete="off"
                                        />
                                        </div>
                                    </div>
                                    {/* <div class="input-group">
                                        <span class="input-group-text" id="search-addon">
                                            <i class="fi fi-rr-search"></i>
                                        </span>
                                        <input type="text" class="form-control" placeholder="Search here..." aria-label="Search" aria-describedby="search-addon"/>
                                    </div> */}
                                </Col>
                                <Col md={3} className="mt-3 pr-0">
                                    <Select
                                        isClearable={false}
                                        onChange={this.handleChange}
                                        options={options}
                                        defaultValue={options[0]}
                                        value={filterOption}
                                        className="basic-select"
                                        classNamePrefix="outbound-select wa-select"
                                    />
                                </Col>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Col md={6} className="pr-0">
                                    <div className="le-form-row">
                                        <div className="input-with-icon">
                                        <span className="search-icon">
                                            <i className="icon-color fi fi-rs-search"></i>
                                        </span>
                                        <Input
                                            type="text"
                                            onChange={this.handleSearchChange}
                                            value={searchValue}
                                            placeholder="Search by Name"
                                            className="search-input"
                                            autoComplete="off"
                                        />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={isAdmin ? 4 : 6} className="pr-0">
                                    <Select
                                        isClearable={false}
                                        onChange={this.handleChange}
                                        options={options}
                                        defaultValue={options[0]}
                                        value={filterOption}
                                        className="basic-select"
                                        classNamePrefix="outbound-select wa-select"
                                    />
                                </Col>
                                <Col md={2} className={`${isAdmin ? "pl-0" : 'hide'}`}>
                                    <Button 
                                        onClick={(e) => this.handleRowClick()} 
                                        className="btn-hover-shine theme-bg-g custom-display add-btn ms-auto me-3" 
                                    >
                                        <i class="fi fi-br-plus"></i>
                                        <span>{titlebtnLabel}</span>
                                    </Button>
                                </Col>
                            </Fragment>
                        )
                    }
                </Row>
            </div>
        );
    }

    render() {
        const {type, columns, formData, mainLoader} = this.state;
        let title = "Campaigns";
        let titlebtnLabel = "New Campaign";
        if(type === "automation"){
            title = "Automations";
            titlebtnLabel = "New Automation";
        }
        return (
            <Fragment>
                <Common 
                    title={title} 
                    titleContent={
                        <span>{title}</span>
                    }
                    mainLoader={mainLoader}
                    titleBody={
                        !isMobile() ? (
                            this.getFilterContainer()
                        ) : (
                            <Button 
                                onClick={(e) => this.handleRowClick()} 
                                className="btn-hover-shine theme-bg-g custom-display add-btn me-auto me-3" 
                            >
                                <i class="fi fi-br-plus"></i>
                                <span>{titlebtnLabel}</span>
                            </Button>
                        )
                    }
                    mainContent={
                        <div className="p-3 outbound-main-container">
                            {
                                isMobile() && this.getFilterContainer()
                            }
                            <div className="p-2">
                            {formData.length > 0 && columns.length > 0 ?(
                                <ListTable data={formData} columns={columns} />):
                                (
                                    !mainLoader && formData.length === 0 && <NoRecordFound img={aiData} isOnlyContent={true} content={"No Users Found."}/>
                                )
                            }
                                                        </div>
                        </div>
                    }
                    titleKey={type === "automation" ? "automation_allowed" : "campaign_allowed"}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutboundService);
