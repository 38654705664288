import React, { useState, useEffect } from 'react';
//External
import cx from "classnames";
import requestApi from "../../services/requestApi.service";
import { showToast,confirmBox,translateContent,getDeleteIcon } from '../../Common/Appcommon';
import { InputGroup } from 'reactstrap';

const CratioFileUploader = props => {

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    const mediaErrorRef = React.useRef(null);
    const [fileName, setFileName] = useState(props.fileName);
    const [mediaError, setMediaError] = useState("");
    useEffect(() => {
        (async () => {
            setFileName(props.fileName);
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, [props]);
    const isFormUploader = props.formUploader || false;
    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const clearFile = async(field) =>{
        props.clearFile(field);
    }
    const validateImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
                if (props.comWidth !== undefined) {
                    let {comWidth, comHeight} = props;
                    if (width <= comWidth && height <= comHeight) { // Example dimensions
                        resolve(true);
                    } else {
                        reject(`Invalid dimensions: ${width}x${height}. Required: ${comWidth}x${comHeight}.`);
                    }
                } else {
                    resolve(true);
                }
            };
            img.onerror = () => reject("Failed to load the image.");
            img.src = URL.createObjectURL(file);
        });
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0];
        let fileName = fileUploaded.name;
        try {
            await validateImageDimensions(fileUploaded);
            if (!isFormUploader) {
                setFileName(fileName);
                props.handleFile(event, props.name);
            } else {
                processFormFileUpload(fileUploaded);
            }
        } catch (err) {
            showToast(err);
        }
    };

    const scrollToMediaerror = () => {
        if (mediaErrorRef !== null && mediaErrorRef.current !== null) {
            mediaErrorRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const processFormFileUpload = async (file) => {
        let params = {
            "ENTITY": "processuploadfile",
            "OPERATION": "Uploading img",
            "imageupload": "1",
            "file": file,
            "isOtherApiReq": true,
            "fileparamname": "importpngimage"
        }
        let response = await requestApi.processFileUpload(params);

        if (response.issuccess) {
            setFileName(response.data);
            setMediaError("");
            props.attributes.onChange({}, { name: props.attributes['fieldName'], value: response.data }, props.attributes);
        } else {
            setMediaError(response.errormsg);
        }
    }

    let filesAccept = ".jpg,.png,.jpeg";
  /*   let btnLbl = "";
    let mediaUrl = "";

    if (isFormUploader) {
        //filesAccept = props.attributes.filesAccept;
        btnLbl = props.attributes.label;
        mediaUrl = props.attributes.value;
    } else {
        //filesAccept = props.filesAccept;
        btnLbl = props.btnLbl;
        mediaUrl = props.mediaUrl;
    } */


    if (fileName !== "No file selected" && fileName !== "" && !isFormUploader) {
        if (mediaError !== "") {
            setMediaError("");
        }
    }
    if (mediaError !== undefined && mediaError !== '' && !isFormUploader) {
        scrollToMediaerror();
    }
    const clearFileName = async() => {
        let confirm = await confirmBox(translateContent("vm.delete.confirm"), translateContent("vm.delete.confirm.msg"), getDeleteIcon(), 'Delete', 'Cancel');
        if (!confirm) {
            return;
        }
        setFileName(""); 
        clearFile(props.name); 
    };

    return (
        <>
            <div className={cx(``, {
                'is-invalid': mediaError !== "",
                'dInlineFlex': !isFormUploader
            })} ref={mediaErrorRef}>
                <InputGroup>
                <div className="file-selection cursor-pointer" onClick={handleClick}>
                    <span className="field-name">Choose file</span>
                    <span className='profile-remove-icon'>{fileName !== undefined ? (fileName === "" ? "No file chosen" : fileName) : ""}</span>
                    <input name="file" id="exampleFile" type="file" className="form-control-file hide" />
                    {props.isAdmin && fileName !== undefined && fileName !== null && fileName !== "" && (
                        <div className="input-group-text cursor-pointer" onClick={(event) => {event.stopPropagation();clearFileName();}}>
                            <i className="fi fi-rr-trash"></i>
                        </div>
                    )}
                </div>
                </InputGroup>
                <input
                    accept={filesAccept}
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    disabled={props.isAdmin ? false: true}
                />
            </div>
            <em className={`error invalid-feedback ${mediaError !== "" ? '' : 'hide'}`}>{mediaError}</em>
        </>
    );
}
export default CratioFileUploader;
