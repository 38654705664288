import { Slide, toast } from "react-toastify";
import swal from "sweetalert";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { format } from 'date-fns';
import parseHtml from 'html-react-parser';
import { loadStripe } from "@stripe/stripe-js";
import requestApi from "../services/requestApi.service";
// import warningIcon from "../assets/utils/images/exclamation.png";
import dateFormat from 'dateformat';
import info from "../assets/utils/images/info.png";
import warning from "../assets/utils/images/warning.png";
import error from "../assets/utils/images/error.png";
import success from "../assets/utils/images/success.png";
import dark_diamond_exclamation_img from "../assets/utils/images/diamond-exclamation-dark.png";
import diamond_exclamation_img from "../assets/utils/images/diamond-exclamation.png";
import TooltipItem from "./ToolTip";
import CratioTextInput from "../Components/Common/CratioFields/CratioTextInput";
import CratioNumberInput from "../Components/Common/CratioFields/CratioNumberInput";
import CratioDropdownField from "../Components/Common/CratioFields/CratioDropdownField";
import CratioDatePicker from "../Components/Common/CratioFields/CratioDatePicker";
import CratioMobileInput from "../Components/Common/CratioFields/CratioMobileInput";
import CratioHyperLink from "../Components/Common/CratioFields/CratioHyperLink";
import CratioCheckBox from "../Components/Common/CratioFields/CratioCheckBox";
import { custom_filter_options } from "../Components/Search/Constants";
import i18n from "../i18n";
import deleteIcon from '../assets/utils/images/trash.png';
import darkDeleteIcon from '../assets/utils/images/dark_trash.png';
import warningIcon from '../assets/utils/images/triangle-warning.png';
import darkWarningIcon from '../assets/utils/images/triangle-warning-dark.png';
import { getGlobalTheme, useTheme } from "../Context/ThemeContext";

class Appcommon {

}
const FIELD_NAME_INDEX = 2;
const FIELD_LABEL_INDEX = 3;
const FIELD_TYPE_INDEX = 4;

export function translateContent(key, params = {} ) {
    return i18n.t(key, params);
}

export function showToast(msg, position = "top-right", type = "success", onclosecb, autoclose = 5000, header = "") {
    let icon = success;
    switch (type) {
        case "error":
            header = "Error";
            icon = error;
            break;
        case "info":
            icon = info;
            header = "Icon";
            break;
        case "warning":
            header = "Warning";
            icon = warning;
            break;
        default:
            header = "Success";
            icon = success;
    }

    header = "";
    const msgContent = (header, body) => {
        return (
            <div>
                <div>
                    <strong>{header}</strong>
                </div>
                <div>
                    {body}
                </div>
            </div>
        )
    }

    toast(
        msgContent(header, msg)
        , {
            theme: "light",
            position: position,
            autoClose: autoclose,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: onclosecb,
            type: type,
            transition: Slide,
            icon: <img src={icon} alt="image_show_to_ast" className="custome-show-toact-icon" />
        });
}


export function setSessionValue(key, value) {
    let prefix = getHostPrefix();
    localStorage.setItem(prefix + key, value);
}

export function getSessionValue(key) {
    let prefix = getHostPrefix();
    let sessionValue = (prefix + key !== undefined && prefix + key !== null && prefix + key !== '') ? prefix + key : key;
    return localStorage.getItem(sessionValue) === null ? '' : localStorage.getItem(sessionValue);
}

export function clearSessionValue() {
    localStorage.clear();
}

export async function confirmBox(title, message, type = 'warning', btntxt = "OK", cancelText = "Cancel") {
    if(type == "diamond-exclamation"){
        type = document.body.classList.contains('dark-theme') ? dark_diamond_exclamation_img : diamond_exclamation_img;
    }
    return await swal({
        title: title,
        text: message,
        icon: type,
        buttons: {
            cancel: {
                text: cancelText,
                value: false,
                visible: true,
                className: "btn btn-link",
                closeModal: true,
            },
            confirm: {
                text: btntxt,
                value: true,
                visible: true,
                className: "btn btn-lg text-bold theme-bg-g",
                closeModal: true
            }
        },
        dangerMode: false,
        confirmButtonColor: "green"
    });
}

export async function confirmBoxWithImage(title, message, imageIcon = "", btntxt = "OK", cancelText = "Cancel", cancelBtn = "", confirmBtn = "", closeOnClickOutside = true, closeOnEsc = true, needToClose = false) {
    if(imageIcon == "diamond-exclamation"){
        imageIcon =  document.body.classList.contains('dark-theme') ? dark_diamond_exclamation_img : diamond_exclamation_img;
    }
    return await swal({
        title: title,
        text: message,
        icon: imageIcon === "" ? warningIcon : imageIcon,
        buttons: {
            cancel: cancelBtn === "" ? {
                text: cancelText,
                value: false,
                visible: cancelText ? true : false,
                className: "btn btn-link",
                closeModal: true,
            } : cancelBtn,
            confirm: confirmBtn === "" ? {
                text: btntxt,
                value: true,
                visible: true,
                className: "btn btn-lg text-bold theme-bg-g",
                closeModal: true
            } : confirmBtn,
            closeBtn: {
                text: 'X',
                value: 'Close',
                visible: needToClose === true ? true : false,
                className: "swal-button--cancel custom-cancel-button",
                closeModal: true,
            },
        },
        imageWidth: 50,
        imageHeight: 50,
        imageAlt: 'Warning',
        closeOnClickOutside: closeOnClickOutside,
        closeOnEsc: closeOnEsc,
    });
}

let linkData = [];
export function setLinkData(ld) {
    linkData = ld;
}

export function getLinkData() {
    return linkData;
}

export function getServerURL() {
    let appHost = window.location.hostname;
    let protocol = window.location.protocol;
    let subName = "/dacamweb";
    let baseUrl = protocol + "//" + appHost + "/server";
    if (appHost !== "localhost") {
        // baseUrl = getServerDomainURL();
    } else {
        baseUrl = protocol + "//" + appHost;
    }
    return baseUrl + subName;
}


export function getServerDomainURL() {
    let appHost = window.location.hostname;
    let serverUrl = process.env.REACT_APP_SERVER_URL;
    if (appHost.indexOf("cratio.com") > 0) {
        serverUrl = process.env.REACT_APP_NEW_SERVER_URL;
    }
    return serverUrl;
}

export async function populateFormDetails(formname, subAccountAPPID = "") {
    let params = {
        "ENTITY": "ApplDirectory",
        "OPERATION": "FormDetail",
        "formnames": [formname],
        "Isbuilderreq": false,
        "ISAPIREQ": true,
        "isSubAccount": false,
    }
    if (subAccountAPPID !== "") {
        params['sub_accountId'] = subAccountAPPID;
        params['isSubAccount'] = true;
    }
    let formData = await requestApi.processServerRequest(params);
    if (formData.issuccess && formData.data.length > 0) {
        let formResponse = formData.data;
        if (formResponse[1] !== undefined) {
            let moduleDetails = formResponse[1];
            let formDetail = moduleDetails[2][0];
            return formDetail;
        }
    }

    return [];
}

export async function getTemplatesFormDetails(formDetail, templateName = "") {

    let selectedTemplate = '';
    let templates = [];
    if (formDetail && formDetail.length > 0) {
        templates = formDetail[4];
        let lastTemplate = formDetail[8].length > 0 ? formDetail[8][0] : [];
        let lastSelectedTemplateId = lastTemplate.length > 0 ? lastTemplate[2] : '';
        for (let key in templates) {
            let template = templates[key];
            if (templateName !== "") {
                if (templateName === template[1]) {
                    selectedTemplate = template;
                }
            } else {
                if (lastSelectedTemplateId === template[0]) {
                    selectedTemplate = template;
                } else if (lastSelectedTemplateId === "") {
                    lastSelectedTemplateId = template[0];
                    selectedTemplate = template;
                }
            }
        }
    }

    return { selectedTemplate, templates }
};

export async function getFormDetails(formname, details) {
    let formDetail = [];
    for (let key in details) {
        for (let dupkey in details[key][0]) {
            if (dupkey === formname) {
                formDetail = details[key][0][dupkey];
                return formDetail;
            }
        }
    }
    return formDetail;
}

export function parseJSON(str) {
    let res = {};
    try {
        if (str !== "" && str !== null) {
            res = JSON.parse(str);
            if (typeof res === 'string') {
                res = JSON.parse(res);
            }
        }
    } catch (e) {
        //console.log(e);
    }
    return res;
}

export async function getGeneralSettings(key, refresh = false) {

    let settings = {};
    let response = "";
    let generalSettings = getSessionValue('generalSettings');

    if (generalSettings !== "" && !refresh) {
        try {
            settings = parseJSON(generalSettings);
        } catch (e) { }
    } else {
        //update general settings if not found in session.
        let params = {
            "ENTITY": "WHATSAPPPROCESS",
            "OPERATION": "GET_GENERALSETTINGS"
        }
        let responseData = await requestApi.processServerRequest(params);

        if (responseData.issuccess) {
            response = responseData.data;
            for (let i in response) {
                settings[response[i][1]] = response[i][2];
            }
            setSessionValue('generalSettings', JSON.stringify(settings));
        } else {
            handleErrorResponse(responseData.errormsg);
        }
    }

    if (settings[key] !== undefined) {
        response = settings[key];
    }

    return response;
}

export function handleErrorResponse(message) {
    if (message.trim() !== "") {
        showToast(message, 'top-right', "error");
    }
}

export function getLogoutURL() {
    let appHost = window.location.hostname;
    let baseUrl = 'https://' + appHost + "/#/login";
    //baseUrl = process.env.REACT_APP_CRATIO_LOGIN_URL;
    //if (appHost.indexOf("cratio.com") > 0) {
    //    baseUrl = process.env.REACT_APP_CRATIO_NEW_LOGIN_URL;
    //}
    return baseUrl;
}

export function getHostPrefix() {
    let serverUrl = getServerDomainURL();
    let prefix = "local_";
    if (serverUrl.indexOf("voicemetrics.ai") !== -1) {
        serverUrl = serverUrl.replace("https://", "");
        let urlArr = serverUrl.split(".");
        if (urlArr.length > 1) {
            prefix = urlArr[0] + "_";
        }
    }
    return prefix;
}

export function clearLegacyCookie() {
    let prefix = getHostPrefix();
    document.cookie = prefix + "loginusername=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.voicemetrics.ai; path=/;";
    document.cookie = prefix + "loginusertableid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.voicemetrics.ai; path=/;";
    document.cookie = prefix + "loginappid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.voicemetrics.ai; path=/;";
}

export function getCookieByHost(name) {
    var nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return ""

}

export async function validateLogin(history, isRedirectNeeded = true, toggleTheme = null, toggleLanguage = null, processCurrency = null) {
    let prefix = getHostPrefix();
    const appid = getCookieByHost(prefix + 'loginappid');
    const usertableid = getCookieByHost(prefix + 'loginusertableid');
    let params = {
        "ENTITY": "ai_login",
        "APPID": appid,
        "usertableid": usertableid,
    }
    let res = await requestApi.processServerRequest(params);
    if (res.issuccess) {
        let loginres = res.data;
        if (loginres.length > 0) {
            let menus = loginres[5];
            let userName = loginres[1];
            let email = loginres[2];
            let orgData = loginres[4];
            let agencyDetails = loginres[6];
            let apiDetails = loginres[7];
            let defaulturl = loginres[8];
            let licensedata = loginres[9];
            let licenseinfo = loginres[10];
            let exportLimit = loginres[17];
            setSessionValue('exportLimit', JSON.stringify(exportLimit));
            setSessionValue('menus', JSON.stringify(menus));
            setSessionValue('loginemail', email);
            setSessionValue('loginuser', userName);
            setSessionValue('licenseinfo', licenseinfo);
            updateOrganizationInfo(orgData);
            if (loginres[11] !== undefined && loginres[11] !== null) {
                setSessionValue('sessionid', loginres[11]);
            } else {
                setSessionValue('sessionid', '');
            }
            updateAgencyInfo(agencyDetails);
            if (licensedata && licensedata.length > 0) {
                setSessionValue("licenseErrorMsg", licensedata[1] ?? "false");
                setLicenseErrorStatus("header_exp_noti", licensedata[0] ?? "");
                setSessionValue("customer_type", licensedata[2]);
                setSessionValue("balance_days", licensedata[3]);
            }
            if (apiDetails !== undefined && apiDetails !== null && apiDetails !== "") {
                let data = apiDetails['providerDetails'];
                let customProvider = apiDetails['provider'];
                if (customProvider) {
                    setSessionValue("agent_provider_name", customProvider.toLowerCase());
                    if (data !== undefined && data.length > 0) {
                        let key = customProvider.toLowerCase() + '_api_keys';
                        setSessionValue(key, data);
                    }
                }
            }
            let isSubAccountView = getSessionValue("isSubAccountView");
            if (isSubAccountView !== 'true') {
                if (loginres[12]['isValidSession'] === false) {
                    clearAllCookies(prefix);
                    window.location = getLogoutURL();
                }
            }
            setSessionValue('profileName', loginres[13]);
            if (loginres[14] !== undefined && loginres[14] !== null) {
                setSessionValue('isadmin', loginres[14]);
            } else {
                setSessionValue('isadmin', 'No');
            }
            if (loginres[15] !== undefined && loginres[15] !== null) {
                let userData = loginres[15];
                await setLoginUserDetails(userData);
                let themeOption = getLoginUserInfo("Account_Type");
                if (toggleTheme !== null && themeOption !== "" && themeOption !== null && (themeOption === "dark-theme" || themeOption === "light-theme")) {
                    toggleTheme(themeOption);
                }
                let language = getLoginUserInfo("Languages");
                if (toggleLanguage !== null && language !== "" && language !== null && language !== "") {
                    if(isSubAccountView !== 'true'){
                        toggleLanguage(language);
                    }
                }
            }
            if (loginres[16] !== undefined && loginres[16] !== null) {
                let editAllow = loginres[16];
                setSessionValue('isAllowEditAgent', editAllow);
            }
            if (loginres[18] !== undefined && loginres[18] !== null) {
                let allowVoice = loginres[18];
                setSessionValue('isAllowVoiceField', allowVoice);
            }
            if (loginres[20] !== undefined && loginres[20] !== null && processCurrency !== null && isSubAccountView !== 'true') {
                let currencyList = loginres[20];
                processCurrency(currencyList);
            }
            if (isRedirectNeeded) {
                history.push(defaulturl);
            }

            await getPickLists();
        } else {
            window.location = getLogoutURL();
        }
    } else {
        window.location = getLogoutURL();
    }
}

export function randstr(prefix) {
    return Math.random().toString(36).replace('0.', prefix || '');
}

export function setGeneralSettingsValues(generalSettings) {
    for (let gs = 0; gs < generalSettings.length; gs++) {
        let propertyName = generalSettings[gs][1];
        let propertyValue = generalSettings[gs][2];
        setSessionValue(propertyName, propertyValue);
    }
}

export async function setLoginUserDetails(userData) {
    for (let key in userData) {
        setSessionValue("userInfo_" + key, userData[key]);
    }
}

export function getLoginUserInfo(key) {
    return getSessionValue("userInfo_" + key);
}

export function setLicenseInfo(licenseInfo) {
    for (let key in licenseInfo) {
        setSessionValue("licenseInfo_" + key, licenseInfo[key]);
    }
}

export function isAddonModuleEnabled(module) {
    let currentmenu = getSessionValue("menus");
    let isEnabled = false;
    if (currentmenu !== "") {
        currentmenu = JSON.parse(currentmenu);
        let addonForms = currentmenu[currentmenu.length - 2];
        for (let af = 0; af < addonForms.length; af++) {
            if (addonForms[af][0] === module) {
                isEnabled = true;
                break;
            }
        }
    }
    return isEnabled;
}

export function setCookie(name, value, days, domain, hours = 0, minutes = 0, seconds = 0) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    // Set the time to the specified hours, minutes, and seconds
    date.setHours(hours, minutes, seconds, 0);

    // Set the cookie with the calculated expiration time
    const expires = `expires=${date.toUTCString()}`;
    if (domain !== "") {
        document.cookie = `${name}=${value};${expires};domain=${domain};path=/`;
    } else {
        document.cookie = `${name}=${value};${expires};path=/`;
    }
}


export function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};

export function constructEmitStr(appid, loginuserid, process) {
    let response = "";
    //console.log("APPID: "+(typeof appid));
    //appid = appid.replaceAll("_", "");
    appid = appid.replace(/_/g, "");
    response = appid + "_";
    if (loginuserid !== "") {
        response += loginuserid + "_";
    }
    response = response + process;
    return response;
}

export function isMobile() {
    const userAgent = navigator.userAgent.toLowerCase();
    const ism = /iphone|ipad|ipod|android|blackberry|windows phone/.test(userAgent);
    return ism;
    //return (window.matchMedia && window.matchMedia("(max-width: 480px)").matches);
};

export function clearAllCookies(prefix) {
    document.cookie = prefix + "loginusername=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.cratiocrm.com; path=/;";
    document.cookie = prefix + "loginusertableid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.cratiocrm.com; path=/;";
    document.cookie = prefix + "loginappid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.cratiocrm.com; path=/;";
}
export function getAppidFromCookie() {
    let prefix = getHostPrefix();
    let appid = getCookieByHost(prefix + 'loginappid');
    return appid;
}

export function openNewTab(url) {
    if (url) {
        window.open(url, '_blank');
    }
}

export async function loadFormData(formname, formDetail, selectedTemplate, subAccountAPPID = "", manualSearchStr = "", skipAppending = true, prevData = [], searchStr = "", isLimit = 0, exportType = "") {
    let page = 1;
    let limit = 50 + isLimit;
    let dataResponse = {
        issuccess: true,
        errormsg: "",
    };
    if (formDetail.length > 0) {
        let template = selectedTemplate;
        let formInfo = formDetail[0];
        let fieldDetails = formDetail[1];
        let moduleName = formInfo[0];
        let formLabel = formInfo[2];
        let templateFieldOrders = template[4];
        let predefinedCondition = "";
        let params = {
            ENTITY: "Search_by_filter",
            OPERATION: "FIND",
            FORMNAME: formname,
            RECORDVALUES: limit,
            MODULENAME: moduleName,
            FORMTYPE: "",
            "Template name": template[1],
            "Template Id": template[0],
            PAGENUMBER: page,
            NUMBEROFROW: limit,
            isOtherApiReq: true,
            isotherapi: true,
            ISAPIREQ: true,
            predefinedCondition: predefinedCondition,
            isSubAccount: false,
        };
        if (searchStr !== "") {
            params = {
                ENTITY: "Search form",
                FORMNAME: formname,
                SEARCHFIELDS: searchStr,
                PREDEFINED: "",
                PAGENUMBER: page,
                NUMBEROFROW: limit,
                isOtherApiReq: true,
                isotherapi: true,
                ISAPIREQ: true,
                isSubAccount: false,
            };
        }
        if (manualSearchStr !== "" || exportType !== "") {
            params = {
                ENTITY: "FORMPROCESS",
                OPERATION: "FIND",
                FORMNAME: formname,
                MODULENAME: moduleName,
                FORMTYPE: "",
                LASTSELECTEDTEMPLATE: template[0],
                PAGENUMBER: page,
                NUMBEROFROW: limit,
                SEARCHFIELDS: {
                    advanced_search_key: manualSearchStr,
                    STARTC: true,
                },
                isOtherApiReq: true,
                isotherapi: true,
                ISAPIREQ: true,
                isSubAccount: false,
            };
            if (exportType !== "") {
                params['filetype'] = exportType;
                params['filename'] = formLabel;
                params['selectedtype'] = 'expwithserrec';
                params['isotherexport'] = true;
            }
        }

        if (subAccountAPPID !== "") {
            params["sub_accountId"] = subAccountAPPID;
            params["isSubAccount"] = true;
        }
        let formresponse = await requestApi.processServerRequest(params, "WebV2");

        if (formresponse.issuccess) {
            let formData = formresponse.data;
            if (searchStr !== "") {
                formData = formData[0][2];
            }
            let totalCount = formData[1];
            let rowData = formData[2];
            if (exportType !== "") {
                dataResponse = {
                    issuccess: true,
                    formData: formresponse.data
                }
                return dataResponse;
            }
            //let rowFieldsInfo = formData[3];
            //let listRowFields = rowFieldsInfo[0][3];
            let listFields = await getListFields(fieldDetails, templateFieldOrders);
            //let tempRowData = await this.reArrangeListRows(listRowFields, fieldDetails, rowData);
            dataResponse = {
                issuccess: true,
                formData: skipAppending
                    ? rowData
                    : [...prevData, ...rowData],
                listFields: listFields[0],
                fieldsSorting: listFields[1],
                totalCount: totalCount,
                showLoader: false,
            }
        } else {
            dataResponse = {
                issuccess: false,
                errormsg: formresponse.errormsg,
            };
        }
    }
    return dataResponse;
}

export async function prepareLabelNameMapper(viewFields) {
    const mapper = {}; // Use `const` since the reference won't change
    if (Array.isArray(viewFields) && viewFields.length > 0) {
        viewFields.forEach((field) => {
            const name = field[2];
            const label = field[3];
            mapper[name] = label;
        });
    }
    return mapper;
}


export async function prepareCustomTableData(formData, listFields, isFirstLink = true, isFirstColumnFixed = true, onViewAccountSettings = () => { }) {
    let data = [];
    let column = [];
    let listNameFields = listFields[0] ?? [];
    let listLabelFields = listFields[1] ?? [];
    let isAdmin = await checkIsAdmin();

    formData && formData.map((d, i) => {
        let item = {};
        listNameFields.map((fname, index) => {
            let value = d[fname];
            if (fname === "call_cost") {
                value = value === "" || value === null ? 0 : value;
                value = "$" + value;
            } else if (fname === "status") {
                let substsval = d['sub_status'];
                let bclass = "";
                let sts = d['status'];
                let appidExists = (d['agency_id'] !== undefined && d['agency_id'] !== null && d['agency_id'] !== "") ? true : false;
                if (appidExists && sts !== undefined && sts !== "" && sts !== null) {
                    sts = sts.toLowerCase();
                    if (sts === "active") {
                        bclass = 'sub-sts-active';
                    } else if (sts === "deactive") {
                        bclass = 'sub-sts-pym-fail';
                    } else if (sts === "maintanance") {
                        bclass = 'sub-sts-maintanace';
                    } else if (sts === "suspended") {
                        bclass = 'sub-sts-suspend';
                    }
                    value = (
                        <div className={`badge ${bclass}`}>
                            {value}
                        </div>
                    );
                }else{
                    value = d[fname]; 
                }

                if (substsval !== undefined && substsval !== null && substsval !== "") {
                    if (substsval.toLowerCase().includes("cancel")) {
                        substsval = "SUBSCRIPTION CANCELED";
                    } else if (substsval.toLowerCase().includes("fail")) {
                        substsval = "SUBSCRIPTION FAILED";
                    }
                    let isPaymentError = false;
                    let splitValues = substsval.split(',').map(value => value.trim());
                    let subvalue = splitValues.map((cval, index) => {
                        let sbclass = "";
                        let sval = cval.toLowerCase();
                        if (sval.includes("api")) {
                            sbclass = 'sub-sts-apierror';
                        } else if (sval.includes("cancel") || sval.includes("deact") || sval.includes("fail")) {
                            sbclass = 'sub-sts-pym-fail';
                            isPaymentError = true;
                        }
                        return (
                            <div key={index} className={`badge txt-ovrflw ${sbclass}`}>
                                {cval}
                            </div>
                        );
                    });
                    value = (
                        <div className="sb-sts-btn-grp sb-sts-tbl">
                            {isPaymentError ? "" : value}
                            {subvalue}
                        </div>
                    );
                }
            } else if (fname === "last_login_at") {
                value = formatDate(convertUTCToLocalTime(value), "mmm dd, yyyy, hh:MM:ss TT");
            } else if (fname === "last_call_time") {
                value = formatDate(convertUTCToLocalTime(value), "mmm dd, yyyy, hh:MM:ss TT");
            } else if (fname === "Start_DateTime") {
                value = formatDate(convertUTCToLocalTime(value), "mmm dd, yyyy, hh:MM:ss TT");
            }else if (fname === "Received_Date") {
                value = formatDateTime(convertUTCToLocalTime(value),"MMM dd, yyyy, hh:mm a");
            }
            item[fname] = value;
        });
        item['data_row'] = d;
        data.push(item);
    });
    listNameFields && listNameFields.map((fname, index) => {
        let columnItem = {};
        let label = listLabelFields[index];
        let name = fname;
        columnItem['name'] = label;
        columnItem['selector'] = row => row[name];
        columnItem['sortable'] = false;
        if (fname === "call_minutes" || fname === "call_cost") {
            if (fname === "call_cost") {
                columnItem['minWidth'] = '106px !important';
            } else {
                columnItem['selector'] = row => row[name];
                columnItem['sortable'] = true;
                columnItem['minWidth'] = '126px !important';
            }
            columnItem['name'] = <TooltipItem id={'1'} text={"Showing last 30 days data"} content={<span className="cursor-pointer head-info custom-display"> {label} <i class="fi fi-rs-info"></i></span>} />;
        }
        if (fname === "account_name") {
            columnItem['minWidth'] = '230px !important';
            columnItem['selector'] = row => row[name];
            columnItem['sortable'] = true;
        }
        if (fname === "last_call_time") {
            columnItem['minWidth'] = '200px !important';
            columnItem['selector'] = row => row[name];
            columnItem['sortable'] = true;
        }
        if (fname === "last_login_at") {
            columnItem['minWidth'] = '200px !important';
            columnItem['selector'] = row => row[name];
            columnItem['sortable'] = true;
        }
        if (fname === "type") {
            columnItem['minWidth'] = '126px !important';
            columnItem['selector'] = row => row[name];
            columnItem['sortable'] = true;
        }
        if (fname === "status") {
            columnItem['minWidth'] = '205px !important';
            if(!isAdmin){
                columnItem['minWidth'] = '230px !important';
            }
            columnItem['selector'] = row => row[name];
            columnItem['sortable'] = true;
        }
        if (fname === "lasthittime") {
            columnItem['minWidth'] = '200px !important';
            if(!isAdmin){
                columnItem['minWidth'] = '400px !important';
            }
        } if (fname === "Start_DateTime") {
            columnItem['minWidth'] = '225px !important';
        } if (fname === "Call_End_Reason") {
            columnItem['minWidth'] = '200px !important';
        } if (fname === "Start_DateTime") {
            columnItem['minWidth'] = '225px !important';
        } if (fname === "Call_End_Reason") {
            columnItem['minWidth'] = '330px !important';
        } if (fname === "Call_Cost") { // call logs
            columnItem['maxWidth'] = '0% !important';
            columnItem['minWidth'] = '90px !important';
        } if (fname === "Durationsec") { // call logs
            columnItem['maxWidth'] = '0% !important';
            columnItem['minWidth'] = '90px !important';
        } if (fname === "Call_Type") { // call logs
            columnItem['maxWidth'] = '0% !important';
            columnItem['minWidth'] = '100px !important';
        } if (fname === "Goal_Achievement") { // call logs
            columnItem['maxWidth'] = '0% !important';
            columnItem['minWidth'] = '120px !important';
        }
        if (fname === "provider") {
            columnItem['selector'] = row => row[name];
            columnItem['sortable'] = true;
            columnItem['minWidth'] = '126px !important';
        }
        if (fname === "webhookname" || name === "lasthittime") {
            columnItem['style'] = {
                width: '30% !important',
            };
            if(!isAdmin){
                columnItem['minWidth'] = '40% !important';
            }
        }
        if (fname === "lasthittime") {
            columnItem['style'] = {
                width: '30% !important',
            };
            columnItem['alias'] = "lasthittime";
        }
        //columnItem['grow'] = 4;
        if (index === 0 && isFirstLink) {
            columnItem["cell"] = (row) => (
                <div>
                    <a onClick={(e) => onViewAccountSettings(row)} className="cursor-pointer text-d-underline font-bold theme-c-link">
                        {row[name]}
                    </a>
                </div>
            );
            if (isFirstColumnFixed) {
                columnItem['style'] = {
                    zIndex: 1, // To make sure it's above other columns when scrolling
                    position: "sticky",
                    left: 0,
                    backgroundColor: "#fff",
                }
            }
        }
        column.push(columnItem);
    });
    return { data: data, columns: column };
}

export async function prepareTableData(formData, listFields, isFirstColumnFixed = true, allFields = [], isFirstLink = true, onFirstColumnClick = () => { }) {
    let tmpData = [];
    let tmpColumn = [];
    let formName = listFields[0][1];
    let bclass = "";
    formData.map((d, i) => {
        let item = {};
        allFields.map((fd, index) => {
            let label = fd[FIELD_LABEL_INDEX];
            let name = fd[FIELD_NAME_INDEX];
            let type = fd[FIELD_TYPE_INDEX];
            if (name === formName + "_id") {
                if (d["Form ID_id"] !== undefined) {
                    label = "Form ID_id";
                } else {
                    label = name;
                }
            }
            if (type === "reference") {
                item[name + "_name"] = d[label];
                label = label + "_id";
            }
            let value = d[label];
            if (name === "Received_Date") {
                value = formatDate(convertUTCToLocalTime(value), "mmmm dd, yyyy hh:MM TT");
            }
            if (name === "Last_Used_Time") {
                value = formatDateTime(convertUTCToLocalTime(value), "MMM dd, yyyy, hh:mm a"); // formatDate((value), "mmm dd, yyyy, hh:MM:ss TT");
            }
            if (name === "vm_contact_status" || name === "VM_Contact_Status") {
                if (d.Status === "Subscribed") {
                    bclass = 'badge badge-info-status-sub';
                } else if (d.Status === "Unsubscribed") {
                    bclass = 'badge badge-info-status-unsub';
                }
                value = (
                    <div className={bclass}>
                        {value}
                    </div>
                );
            }
            item[name] = value;
        });
        tmpData.push(item);
    });

    listFields.map((fd, index) => {
        let columnItem = {};
        let label = fd[FIELD_LABEL_INDEX];
        let name = fd[FIELD_NAME_INDEX];
        columnItem['selector'] = row => row[name];
        columnItem['sortable'] = false;
        if (name === "Last_Used_Time") {
            label = "Last Active Time";
            columnItem['minWidth'] = '22% !important';
            columnItem['sortable'] = true;
        }
        if (name === "table_6_3_table_6_id") {
            columnItem['sortable'] = true;
        }
        if (name === "table_2_0_table_2_id") {
            columnItem['sortable'] = true;
        }
        if (name === "MobileNo") {
            columnItem['sortable'] = true;
        }
        if (name === "Name") {
            columnItem['sortable'] = true;
        }
        if (name === "Name") {
            columnItem['sortable'] = true;
        }
        if (name == "Emailid") {
            columnItem['minWidth'] = '200px !important';
            columnItem['sortable'] = true;
        }
        if(name === "Account_Status"){
            columnItem['width'] = '12% !important';
            columnItem['maxWidth'] = '0% !important';
        }
        if (name === "Received_Date") {
            columnItem['width'] = '20% !important';
            columnItem['maxWidth'] = '0% !important';
        }
        if (name === "status") {
            columnItem['width'] = '10% !important';
            columnItem['maxWidth'] = '0% !important';
        }
        if (name === "Reason") {
            columnItem['width'] = '35% !important';
            columnItem['maxWidth'] = '0% !important';
        }
        if (name === "dataobj") {
            columnItem['width'] = '35% !important';
            columnItem['maxWidth'] = '0% !important';
        }

        columnItem['name'] = label;
        columnItem['alias'] = name;
        if (index === 0) {
            if (isFirstLink) {
                columnItem["cell"] = (row) => (
                    <div>
                        <a onClick={(e) => onFirstColumnClick(row)} className="cursor-pointer text-d-underline font-bold theme-c-link">
                            {row[name]}
                        </a>
                    </div>
                );
            }
            if (isFirstColumnFixed) {
                columnItem['style'] = {
                    zIndex: 1, // To make sure it's above other columns when scrolling
                    position: "sticky",
                    left: 0,
                    //backgroundColor: "#fff",
                }
            }
        }
        tmpColumn.push(columnItem);
    });

    return { data: tmpData, columns: tmpColumn };
}

export async function getListFields(fieldDetails, viewFieldorders) {
    let fieldOrders = [];
    let fieldSorting = [];
    if (viewFieldorders !== undefined) {
        fieldOrders = await getFieldOrdersList(viewFieldorders);
        fieldSorting = await getFieldSortingList(viewFieldorders);
    }

    let listViewFields = [];
    let viewFields = [];
    for (let fo = 0; fo < fieldOrders.length; fo++) {
        let tempFieldOrder = fieldOrders[fo];
        for (let fi = 0; fi < fieldDetails.length; fi++) {
            let field = fieldDetails[fi];
            // let fieldName = field[2];
            // let fieldLabel = field[3];
            let fieldType = field[4];
            if (fieldType === "reference_entityid") {
                continue;
            }
            let fieldOrder = field[22];
            if (tempFieldOrder === fieldOrder) {
                viewFields.push(field);
            }
        }
    }
    listViewFields.push(viewFields);
    listViewFields.push(fieldSorting);
    return listViewFields;
}

export async function getFieldOrdersList(viewFieldorders) {
    let viewFieldorderArr = viewFieldorders.split(",");
    let fieldOrderArr = [];
    for (let i = 1; i < viewFieldorderArr.length; i = i + 2) {
        fieldOrderArr.push(viewFieldorderArr[i - 1]);
    }
    return fieldOrderArr;
}

export async function getFieldSortingList(viewFieldorders) {
    let viewFieldorderArr = viewFieldorders.split(",");
    let fieldSortingArr = [];
    for (let i = 1; i < viewFieldorderArr.length; i = i + 2) {
        fieldSortingArr.push(viewFieldorderArr[i]);
    }
    return fieldSortingArr;
}

export function formatDate(datevalue, format = "yyyy-mm-dd HH:MM:ss") {
    return datevalue && datevalue !== "Invalid Date" ? dateFormat(datevalue, format) : "";
}


export function formatTime(duration) {
    //const hrs = Math.floor(duration / 3600);
    const mins = Math.floor((duration % 3600) / 60);
    const secs = Math.floor(duration % 60);
    //return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

export function getLastCustomDaysRange(dayscount = 30) {
    const today = new Date();
    const endDate = today;
    const startDate = new Date();
    startDate.setDate(today.getDate() - dayscount);

    const options = { month: 'short', day: 'numeric' };
    const formattedStart = startDate.toLocaleDateString('en-US', options);
    const formattedEnd = endDate.toLocaleDateString('en-US', options);

    return `${formattedStart} - ${formattedEnd}`;
}

export function getAgencyInfo(name = "all") {
    let res = "";

    const agencyInfo = parseJSON(getSessionValue('agencyInfo'));
    if (agencyInfo !== null && typeof agencyInfo !== undefined && Object.keys(agencyInfo).length > 0) {
        if (name === "all") {
            res = agencyInfo;
        } else {
            res = agencyInfo[name];
        }
    }

    return res;
}

export function getOrganizationInfo(name = "all") {
    let res = "";

    const orgInfo = parseJSON(getSessionValue('orgInfo'));
    if (orgInfo !== null && typeof orgInfo !== undefined && Object.keys(orgInfo).length > 0) {
        if (name === "all") {
            res = orgInfo;
        } else {
            res = orgInfo[name];
        }
    }

    return res;
}

export function updateOrganizationInfo(data, name = "all", value = "") {
    let orgInfo = {};

    if (name === "all") {
        if (data !== undefined && data !== "" && data !== null) {
            orgInfo = data;
        }
        orgInfo = JSON.stringify(data);
        setSessionValue('orgInfo', orgInfo);
    } else if (name !== "all" && value !== "") {
        orgInfo = getSessionValue('orgInfo');
        orgInfo = JSON.parse(orgInfo);
        orgInfo[name] = value;
        orgInfo = JSON.stringify(data);
        setSessionValue('orgInfo', orgInfo);
    }
}

export function getLicenseInfo(name = "all") {
    let res = "";
    let licenseinfo = getSessionValue('licenseinfo');
    licenseinfo = base64_decode(licenseinfo);
    licenseinfo = parseJSON(licenseinfo);
    if (licenseinfo !== null && typeof licenseinfo !== undefined && Object.keys(licenseinfo).length > 0) {
        if (name === "all") {
            res = licenseinfo[0];
        } else {
            res = licenseinfo[0][name];
        }
    }

    return res;
}

export function updateLicenseInfo(data, name = "all", value = "") {
    let licenseInfo = {};

    if (name === "all") {
        if (data !== undefined && data !== "" && data !== null) {
            licenseInfo = data;
        }
        licenseInfo = JSON.stringify(data);
        licenseInfo = base64_encode(licenseInfo);
        setSessionValue('licenseinfo', licenseInfo);
    } else if (name !== "all" && value !== "") {
        licenseInfo = getLicenseInfo();
        licenseInfo[name] = value;
        licenseInfo = JSON.stringify(data);
        licenseInfo = base64_decode(licenseInfo);
        setSessionValue('licenseinfo', licenseInfo);
    }
}

export function getPaymentDetails(name = "all") {
    let res = "";
    let licenseInfo = getLicenseInfo();
    if (licenseInfo !== null && typeof licenseInfo !== undefined && Object.keys(licenseInfo).length > 0) {
        let paymentdetails = parseJSON(licenseInfo["paymentdetails"]);
        if (name === "all") {
            res = paymentdetails;
        } else {
            res = paymentdetails[name];
        }
    }

    return res;
}

export function updateAgencyInfo(data, name = "all") {
    let agencyInfo = {};

    if (name === "all") {
        if (data !== undefined && data !== "" && data !== null) {
            agencyInfo = data.length > 0 ? data[0] : {};
            agencyInfo = JSON.stringify(agencyInfo);
            setSessionValue('agencyInfo', agencyInfo);
        }

    }
}


export async function uploadFileIntoS3(e, formData, name) {

    const selectedFile = e.target.files[0];
    const fileType = selectedFile.type;
    const fileSize = selectedFile.size;

    let resetInput = false;
    let formInputData = {};

    if (fileType.includes("image/") && fileSize > 5 * 1000 * 1000) {
        showToast("Error! File size must be less than 5MB.", 'top-right', 'error');
        resetInput = true;
    } else if (fileType.includes("video/") && fileSize > 16 * 1000 * 1000) {
        showToast("Error! File size must be less than 16MB.", 'top-right', 'error');
        resetInput = true;
    } else if (fileType.includes("audio/") && fileSize > 16 * 1000 * 1000) {
        showToast("Error! File size must be less than 16MB.", 'top-right', 'error');
        resetInput = true;
    } else if ((fileType.includes("text/") || fileType.includes("application/")) && fileSize > 100 * 1000 * 1000) {
        showToast("Error! File size must be less than 100MB.", 'top-right', 'error');
        resetInput = true;
    } else {
        let params = {
            "ENTITY": "WHATSAPPPROCESS",
            "OPERATION": "FILE_UPLOAD",
            "file": selectedFile,
            "isOtherApiReq": true,
        }
        let res = await requestApi.processFileUpload(params);
        if (res.issuccess) {
            let response = res.data;
            formInputData = formData;
            let fieldData = {};
            fieldData["mediaUrl"] = response.mediaUrl;
            fieldData["mediaType"] = response.mediaType;
            fieldData["fileName"] = response.mediaName;
            formInputData[name] = fieldData;
            showToast("Media Uploaded Successfully.", "top-right");
        } else {
            resetInput = true;
            showToast(res.errormsg, "top-right", "error");
        }
    }

    return { resetInput, formInputData };
}

export async function processImportNumber(importNumber, operation) {

    let page = 1;
    let limit = 50;
    let dataResponse = {
        issuccess: true,
        errormsg: "",
    };
    let formname = "table_247";
    let moduleName = 'Agent details';

    let phoneNumberId = importNumber.table_247_id;
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "PROCESS_IMPORTED_NUMBER",
        SUBOPERATION: operation,
        FORMNAME: formname,
        RECORDVALUES: limit,
        MODULENAME: moduleName,
        RECORDID: phoneNumberId,
        FORMTYPE: "",
        PAGENUMBER: page,
        NUMBEROFROW: limit,
        isOtherApiReq: true,
        isotherapi: true,
        ISAPIREQ: true,
        isSubAccount: false,
    };

    let formresponse = await requestApi.processServerRequest(params, "WebV2");
    if (formresponse.issuccess) {
        let formData = formresponse.data;

        dataResponse = {
            issuccess: true,
            formData: formData,
            showLoader: false,
        }
    } else {
        dataResponse = {
            issuccess: false,
            errormsg: formresponse.errormsg,
        };
    }
    return dataResponse;
}

export async function getReferenceList(refDetails, defaultVal = "", prefix = "") {

    if (refDetails.length > 0) {
        let params = {
            ENTITY: "FORMPROCESS",
            OPERATION: "GET_ALL_REFERENCE",
            REFMODULENAME: refDetails[0],
            REFFORMNAME: refDetails[1],
            REFFIELDNAME: refDetails[2],
            REFNTHINSTANCE: refDetails[3],
            DEFAULTDATA: defaultVal,
            SEARCHVALUE: prefix,
        };
        let response = await requestApi.processServerRequest(params, "WebV2");
        return response;

    }
};

export function isAgentAccount() {
    let account_id = getOrganizationInfo("Account_ID");
    let isAgency = false;
    if (account_id && account_id !== "" && account_id !== null && account_id.indexOf("_") > -1) {
        let accountInfo = account_id.split("_");
        if (accountInfo.length > 0 && accountInfo[0].toLowerCase() === "agent") {
            isAgency = true;
        }
    }
    return isAgency;
}

export function maskKey(value) {
    value = value.toString();
    let masked = value;
    const firstUnderscore = value.indexOf('_');
    const secondUnderscore = value.indexOf('_', firstUnderscore + 1);
    if (secondUnderscore !== -1) {
        const prefix = value.slice(0, secondUnderscore + 1);
        const suffix = value.slice(-5);
        const maskLength = value.length - prefix.length - suffix.length;
        masked = `${prefix}${'*'.repeat(maskLength)}${suffix}`;
    }
    return masked;
};

export async function getLiceneInfo() {
    let response = [];
    const params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "GET_LICENSE_INFO",
        isOtherApiReq: true,
    };

    try {
        let res = await requestApi.processServerRequest(params);
        if (res.issuccess) {
            let data = res.data
            let payment_details = data[0]['paymentdetails'];
            if (payment_details === "") {
                payment_details = {};
            } else {
                payment_details = parseJSON(payment_details)
            }
            data[0]['paymentdetails'] = payment_details;
            response = data;
        }
    } catch (error) {
        console.error("Error fetching license info:", error);
    }

    return response;
}

export async function getCredentials(isAgency = false) {
    let response = [];
    const params = {
        ENTITY: "STRIPE_PROCESSOR",
        OPERATION: "GET_CREDENTIAL",
        isOtherApiReq: true,
        isAgency: isAgency
    };
    try {
        let res = await requestApi.processServerRequest(params);
        if (res.issuccess) {
            let decode_data = base64_decode(res.data);
            let credential = parseJSON(decode_data);
            if (credential['pub_key'] !== undefined && credential['pub_key'] !== null && credential['pub_key'] !== "") {
                let pub_key = base64_decode(credential.pub_key);
                pub_key = parseJSON(pub_key);
                if (pub_key !== "") {
                    response = loadStripe(pub_key);
                }
            }
        } else if (res.errormsg) {
            showToast(res.errormsg, "top-right", "error");
        }
    } catch (error) {
        console.error("Error fetching plan pricing:", error);
    }
    return response;
}

export async function getCurrency(isAgency){
    let response = [];
    const params = {
        ENTITY: "STRIPE_PROCESSOR",
        OPERATION: "GET_CURRENCY",
        isOtherApiReq: true,
        isAgency: isAgency
    };

    try {
        let res = await requestApi.processServerRequest(params);
        if (res.issuccess) {
            response = res.data;
        }
    } catch (error) {
        console.error("Error fetching plan pricing:", error);
    }
    return response;
}

export function getCurrentDomain() {
    const hostname = window.location.hostname;
    /* let agencyData = [];
    const parts = hostname.split(".");
    let subDomain = "";
    if (parts.length >= 3) {
        subDomain = parts[0];
    } */
    return hostname;
}

export async function fetchAgencyDetails() {
    let agencyData = [];
    let subDomain = getCurrentDomain();
    if (subDomain !== "") {
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION': 'AGENCY_INFO',
            'domain': subDomain
        };
        let response = await requestApi.processServerRequest(params, "WebV2", "POST", true);
        if (response.issuccess) {
            agencyData = response.data;
        }
    }
    return agencyData;
}

export function getLicenseErrorStatus(name) {
    let response = getCookie(name) ?? "true";
    return parseJSON(response);
}

export function setLicenseErrorStatus(name, status) {
    let cur_status = getLicenseErrorStatus(name);
    if (cur_status) {
        setCookie(name, status, 1, "");
        return;
    }
}

export function getLicenseErrorMsg() {
    return getSessionValue('licenseErrorMsg');
}

export async function getHeaderNotification() {

    let notification = {
        show: false,
        text: "",
        icon: true,
        btnShow: false,
        btnText: "",
    }

    let licenseErrorStatus = getLicenseErrorStatus("header_exp_noti");
    let licenseErrorMsg = getLicenseErrorMsg();
    if (licenseErrorStatus && licenseErrorMsg !== "") { //show nofitfication when License has expired.
        notification.show = true;
        //notification.text = parseHtml("<p className=\"notification-text\">Action Needed! Your CRM account subscription has expired. Please subscribe to a plan to continue using your account.</p>");
        notification.text = parseHtml("<p className=\"notification-text\">" + licenseErrorMsg + "</p>");

        const curURL = window.location.href;
        if (curURL && (curURL.includes(`/#/subscription`))) {
            notification.show = false;
        }

    }

    return notification;
}

export function formatDateTime(date, fstr = "MMM dd, yyyy, hh:mm:ss a") {
    if (date !== undefined && date !== null && date !== "") {
        let timeZone = getLoginUserInfo("Time_Zone");
        if (timeZone !== "" && timeZone.includes("-")) {
            timeZone = timeZone.split(")-");
            timeZone = timeZone[1];
        } else {
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timeZone,
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        });
        const formattedDate = formatter.format(new Date(date));
        return format(new Date(formattedDate), fstr);
    }
    return "";
}

export const getCurrencySymbol = (currencyCode = "usd") => {
    if(currencyCode !== '' && currencyCode !== null){
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode
        }).formatToParts(1).find(part => part.type === 'currency').value;    
    }else{
        console.error('CurrencyCode Empty');
    }
   };
export function tostring(value) {
    return value.toLocaleString("en-US");
};

export async function syncOperation(operation) {
    let prefix = getHostPrefix();
    const appid = getCookieByHost(prefix + 'loginappid');
    const usertableid = getCookieByHost(prefix + 'loginusertableid');
    let params = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION": operation,
        "APPID": appid,
        "usertableid": usertableid,
    }
    let res = await requestApi.processServerRequest(params);
    if (res.issuccess) {

    }
}

export async function findTimeDiff(targetTime) {
    const now = new Date();
    const timestamp = new Date(targetTime);
    const differenceInMs = now.getTime() - timestamp.getTime();
    const differenceInHours = parseInt(differenceInMs / (1000 * 60 * 60), 10);
    return differenceInHours;
}

export function findTimeDiffAsync(targetTime) {
    const now = new Date();
    const timestamp = new Date(targetTime);
    const differenceInMs = now.getTime() - timestamp.getTime();
    const differenceInHours = parseInt(differenceInMs / (1000 * 60 * 60), 10);
    return differenceInHours;
}

export async function updateBillInfo(orgDetails, paymentDetail) {
    orgDetails.refill_min = paymentDetail?.refill_min ?? "";
    orgDetails.Name = getSessionValue('loginusername');
    let params = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION": "UPDATE_BILLING_INFO",
        "ORG_INFO": orgDetails,
    }
    let formresponse = await requestApi.processServerRequest(params, "WebV2");
    if (formresponse.issuccess) {
        updateOrganizationInfo(orgDetails);
    }
}

export async function getCurrentPlanInfo(isAgency = false) {
    let response = [];
    const params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "GET_CURRENT_PLAN_INFO",
        isAgency, isAgency,
        isOtherApiReq: true,
    };

    try {
        let result = await requestApi.processServerRequest(params);
        if (result.issuccess) {
            let data = result.data;
            response = data;
        }
    } catch (error) {
        console.error("Error fetching dashboard summary:", error);
    }
    return response;
}

export const currencyFormatter = (contryCode = 'en-US', currency = 'USD', number, minFraction = 0) => {

    let res = number;
    try {
        const formatter = new Intl.NumberFormat(contryCode, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: minFraction,
        });
        res = formatter.format(number);

    } catch (e) { }

    return res;
}

export async function getRetelllToken(apiKey, AGENTID) {
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "GETRETELLTOKEN",
        APIKEY: apiKey,
        AGENTID: AGENTID,
    };
    let response = await requestApi.processServerRequest(params, "WebV2");
    return response;
};

export async function updatewebAgentId(agId, webAgId) {
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "UPDATEWEBAGENT",
        AGENTID: agId,
        WEB_AGENT_ID: webAgId,
    };
    let response = await requestApi.processServerRequest(params, "WebV2");
    return response;
};
export function convertUTCToLocalTime(utcTime) {
    if (utcTime !== "" && utcTime !== undefined && utcTime !== null) {
        utcTime = utcTime.replace(" ","T");
        utcTime = utcTime + "Z";
        let timeZone = getLoginUserInfo("Time_Zone");
        if (timeZone !== "" && timeZone.includes(")-")) {
            timeZone = timeZone.split(")-");
            timeZone = timeZone[1];
        } else {
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const date = new Date(utcTime);
        return date.toLocaleString('en-US', {
            timeZone,
            timeZoneName: 'short',
        });
    } else {
        return "";
    }
}

export async function getPlanPricing(isAgency = false) {
    let response = [];
    const params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "GET_SUB_PLAN_PRICING",
        isOtherApiReq: true,
        isAgency: isAgency
    };

    try {
        let res = await requestApi.processServerRequest(params);
        if (res.issuccess) {
            response = res.data;
        }
    } catch (error) {
        console.error("Error fetching plan pricing:", error);
    }
    return response;
}

export function getTimeZonesList() {
    let response = [];
    let pickListItems = getPickListItems("Time Zone");
    pickListItems.map((listItem, index) => {
        response.push({ value: listItem, label: listItem });
    });
    return response;
}

export function getObjectByValue(arrobject, value) {
    let res = arrobject.find(arrobject => arrobject.value === value);
    return res ?? []
};

export function addExtraDate(dateString, add = 1, type = "days") {
    const date = new Date(dateString); // Convert string to Date object
    switch (type.toLowerCase()) {
        case "days":
            date.setDate(date.getDate() + add);
            break;
        case "months":
            date.setMonth(date.getMonth() + add);
            break;
        case "years":
            date.setFullYear(date.getFullYear() + add);
            break;
        default:
            throw new Error("Invalid type. Use 'days', 'months', or 'years'.");
    }

    return date.toISOString().split('T')[0]; // Return in YYYY-MM-DD format
}

export async function getSubaccountCount(){
    let count = 0;
    let agentId = getOrganizationInfo("Account_ID");
    let params = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION": "SUBACCOUNT_COUNT",
        "AGENT_ID": agentId,
    }
    let response = await requestApi.processServerRequest(params, "WebV2");
    if(response.issuccess){
        let data = response.data;
        count = data[0] ?? 0;
    }
    return count;
}

export async function getLicenseinfoByAppid(appid){
    let response = [];
    let params = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION": "GET_LICENSE_INFO_BY_APPID",
        "subaccount_appid": appid,
    }
    let result = await requestApi.processServerRequest(params, "WebV2");
    if(result.issuccess){
        response = result.data;
    }
    return response;
}


export async function exportFormData(formname, state, filename, limit = "10", offset = "0", summertCondition) {
    let params = {};
    let iserror = false;
    let customCondition = summertCondition;
    params["ENTITY"] = "AIPROCESSOR";
    params["OPERATION"] = "EXPORT_CALL_LOGS";
    params["FORMNAME"] = formname;
    params["customCondition"] = customCondition;
    params["type"] = "calls_analytics_logs";
    params["offset"] = offset;
    params["limit"] = limit;
    let response = await requestApi.processServerRequest(params);
    if (response.issuccess) {
        let data = response.data;
        if (data !== "" && data.length > 0) {
            await downloadFile(filename, [data]);
            showToast(translateContent("vm.filedownload.msg",{filename: filename}));
        } else {
            iserror = true;
        }
    } else {
        iserror = true;
    }
    if (iserror) {
        showToast("(#10008) Some thing went wrong...", "top-right", "error");
    }
}

export async function isValidData(value) {
    let valid = false;
    if (value !== "" && value !== null && typeof value !== "undefined") {
        valid = true;
    }
    return valid;
}

export async function handleAudioOperation(audioUrl, currentAudioState) {
    // Pause the current audio if playing
    if (currentAudioState?.isPlaying) {
        currentAudioState.audio.pause();
        currentAudioState.audio.currentTime = 0; // Reset playback to the start
        return { isPlaying: false, audio: null };
    }
    // Play new audio
    const audio = new Audio(audioUrl);
    await audio.play().catch((error) => {
        console.error("Error playing audio:", error);
    });
    // Return updated state for the audio
    return { isPlaying: true, audio };
}

export async function stopAudio(audioStates) {
    Object.keys(audioStates).forEach((index) => {
        if (audioStates[index]?.isPlaying) {
            audioStates[index].audio.pause();
            audioStates[index].audio.currentTime = 0;
        }
    });
}


export async function getPickLists() {
    let params = {
        "ENTITY": "PickList",
        "OPERATION": "GETALL",
    };

    let response = await requestApi.processServerRequest(params, 'WebV2', 'post');
    if (response.issuccess) {
        let pickListMapper = {};
        let allPickLists = response.data;
        for (let i = 0; i < allPickLists.length; i++) {
            let pickList = allPickLists[i];
            let picklistName = pickList[0];
            let pickListItems = [];
            for (let j = 4; j < pickList.length; j++) {
                pickListItems.push(pickList[j]);
            }
            pickListMapper[picklistName] = pickListItems;
        }
        setSessionValue('picklist_details', JSON.stringify(pickListMapper));
    }
}

export function getPickListItems(listName) {
    let pickListMappers = getSessionValue('picklist_details');
    pickListMappers = parseJSON(pickListMappers);
    if (pickListMappers[listName] === undefined) {
        return [];
    }
    let pickList = pickListMappers[listName];
    return pickList;
}

export function getFieldWidget(fieldDetail, onChange, errors = {}, dataRow = {}, field = {}, formId = "", isNew = false) {
    if (fieldDetail !== undefined) {
        let fieldType = fieldDetail[4];
        let fieldName = fieldDetail[2];
        let fieldLabel = fieldDetail[3];
        let defaultValue = fieldDetail[19];
        let formName = fieldDetail[1];
        let fLength = fieldDetail[5];
        let attributes = { fieldLabel: fieldLabel, fieldName: fieldName, fieldType: fieldType, formname: formName, fLength: fLength };
        let controls = { maxlength: fLength };
        let fieldValue = dataRow[fieldLabel] !== undefined ? dataRow[fieldLabel] : "";
        if (fieldType === "Text") {
            return (
                <CratioTextInput value={fieldValue} customClass={`w-100`} inputClass={`${errors[fieldName + "_fieldinput"] ? 'is-invalid' : ''}`} onChange={onChange} attributes={attributes} controls={controls} />
            )
        } else if (fieldType === "MobileNo") {
            let placeholder = "+14158516395";
            let externalNote = true;
            return (
                <CratioMobileInput value={fieldValue} customClass={`w-100`} inputClass={`${errors[fieldName + "_fieldinput"] ? 'is-invalid' : ''}`} onChange={onChange} attributes={attributes} controls={controls} placeholder={placeholder} externalNote={externalNote} />
            )
        } else if (fieldType === "Int") {
            return (
                <CratioNumberInput value={fieldValue} customClass={`w-100`} inputClass={`${errors[fieldName + "_fieldinput"] ? 'is-invalid' : ''}`} onChange={onChange} attributes={attributes} controls={controls} />
            )
        } else if (fieldType === "ComboBox") {
            return (
                <CratioDropdownField
                    value={fieldValue}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    controls={controls}
                    attributes={attributes}
                    customClass={`w-100`}
                />
            )
        } else if (fieldType === "MultiComboBox") {
            let linkUrl = fieldDetail[8];
            attributes['custom_property'] = linkUrl;
            return (
                <CratioDropdownField
                    value={fieldValue}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    controls={controls}
                    attributes={attributes}
                    isMulti={true}
                    customClass={`w-100`}
                />
            )
        } else if (fieldType === "date") {
            return (
                <CratioDatePicker
                    value={fieldValue}
                    onChangeEvent={onChange}
                    defaultValue={defaultValue}
                    controls={controls}
                    attributes={attributes}
                    customClass={`w-100`}
                    placeHolder={"Select.."}
                />
            )
        } else if (fieldType === "subtable" && (fieldName === "table_82_0_table_82" || fieldName === "table_81_0_table_81")) {
            let fieldArr = fieldName.split("_");
            let tagFormname = fieldArr[0] + "_" + fieldArr[1];
            attributes['custom_property'] = fieldName;
            attributes['tag_formname'] = tagFormname;
            return (
                <CratioDropdownField
                    value={dataRow[fieldLabel]}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    controls={controls}
                    attributes={attributes}
                    isMulti={true}
                    customClass={`w-100`}
                    isNew={isNew}
                    formId={formId}
                    baseForm={formName}
                />
            )
        } else if (fieldType === "Hyperlink") {
            return (
                <CratioHyperLink value={fieldValue} customClass={`w-100`} inputClass={`${errors[fieldName + "_fieldinput"] ? 'is-invalid' : ''}`} onChange={onChange} attributes={attributes} controls={controls} />
            )
        } else if (fieldType === "CheckBox") {
            return (
                <CratioCheckBox value={fieldValue} customClass={`w-100`} inputClass={`${errors[fieldName + "_fieldinput"] ? 'is-invalid' : ''}`} onChange={onChange} attributes={attributes} controls={controls} defaultValue={defaultValue} />
            )
        }
    }
}

export function loadListOptions(fd) {
    let defaultValue = fd[19];
    let listOptions = [];
    if (defaultValue !== "" && defaultValue !== undefined) {
        let pickListDetials = defaultValue.split(",");
        let pickListName = pickListDetials[0];
        let pickListItems = getPickListItems(pickListName);
        pickListItems.map((listItem, index) => {
            listOptions.push({ value: listItem, label: listItem });
        });
    }
    return listOptions;
};

export function getFieldNameandFDMapper(fieldDetails) {
    let mapper = {};
    fieldDetails = [...fieldDetails, ...custom_filter_options];
    for (let fi = 0; fi < fieldDetails.length; fi++) {
        let fd = fieldDetails[fi];
        let formname = fd[1];
        let fieldName = fd[2];
        mapper[fieldName] = fd;
    }
    return mapper;
}

export function prepareSearchConditionComponent(conditiontxt, formDetail) {
    let arr = conditiontxt.split("#");
    let fieldDetails = formDetail.length > 0 ? formDetail[1] : [];
    let fieldMapper = getFieldNameandFDMapper(fieldDetails);
    let searchComponents = [];
    for (let a = 0; a < arr.length; a++) {
        let components = {};
        let condition = arr[a];
        if (condition === "") {
            continue;
        }
        condition = condition.replace("@ @", "");
        let condarr = condition.split("@");

        if (condarr.length === 6) {
            let isdate = condarr[0];
            let formName = condarr[1];
            let fieldName = isdate === "spl" ? condarr[1] : condarr[2];
            if (formName.split("_").length === 3) {
                let fieldarr = formName.split("_");
                fieldName = formName + "_table_" + fieldarr[1] + "_id";
            }
            if (isdate === "linkspl") {
                fieldName = fieldName + "_0_" + fieldName;
            }

            let fd = fieldMapper[fieldName];
            if (fd === undefined || fd === null) {
                continue;
            }
            components['fieldDetail'] = fd;
            components['errors'] = {};
            let changes = {};
            let conditions = {};
            if (isdate === "date") {
                let operator = condarr[3];
                changes['operator_0'] = operator;
                let value = condarr[4];
                if (operator === "Before" || operator === "After" || operator === "Below" || operator === "Above") {
                    let valarr = value.split("$");
                    changes['value_0'] = valarr[0];
                    changes['value_1'] = valarr[1];
                } else if (operator === "Custom") {
                    let valarr = value.split("|");
                    changes["value_0"] = "";
                    changes["value_1"] = "";
                    changes['value_2'] = valarr[0];
                    changes['value_3'] = valarr[1];
                } else if (value !== undefined) {
                    changes['value_2'] = value;
                    changes['value_2'] = value;
                }
            } else if (isdate === "nondate") {
                changes['operator_0'] = condarr[3];
                changes['value_0'] = condarr[4];
            } else if (isdate === "spl") {
                changes['operator_0'] = condarr[2];
                changes['value_0'] = condarr[3];
                changes['value_1'] = condarr[4];
            } else if (isdate === "linkspl") {
                let value_0_arr = parseJSON(condarr[4]);
                let value_0 = "";
                value_0_arr.map((value) => {
                    value_0 += value.value + ",";
                })
                if (value_0 !== "") {
                    value_0 = value_0.replace(/^,|,$/g, "")
                }
                changes['operator_0'] = condarr[3];
                changes['value_0'] = value_0;
            }
            conditions['condition_0'] = condarr[condarr.length - 1];
            components['changes'] = changes;
            components['condition'] = conditions;
        }

        searchComponents.push(components);
    }
    return searchComponents;
}

export async function getEncodeedLicense() {
    let res = "";
    const params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "GET_ENCODED_LICENSE",
        isOtherApiReq: true,
    };

    try {
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            res = data;
        }
    } catch (error) {
        console.error("Error fetching dashboard summary:", error);
    }
    return res;
}

export function getUpdatedFieldValue(valueInfo, attributes, dataRow) {

    let label = attributes['fieldLabel'];
    let fieldName = attributes['fieldName'];
    let fieldType = attributes['fieldType'];
    let value = "";
    if (fieldType === "date") {
        value = valueInfo;
        if (value !== "") {
            value = formatDate(value, "yyyy-mm-dd");
        }
    } else if (fieldType === "MultiComboBox") {
        value = JSON.stringify(valueInfo);
    } else {
        value = valueInfo['value'];
        if (fieldType === "entity_group") {
            value = "entity@" + value;
        }
        if (fieldType === "reference" || fieldType === "entity_group") {
            let refFieldKey = fieldName.replace("_id", "_name");
            dataRow[refFieldKey] = valueInfo['label'];
        }
    }
    // dataRow[label] = value;
    return value;
    // return dataRow;
}

export async function prepareDefaultValueList(formdetails) {
    let fieldDetails = formdetails[1];
    let dataRow = {};
    fieldDetails.map((fielddetail) => {
        let fieldName = fielddetail[2];
        let fieldLabel = fielddetail[3];
        let fieldType = fielddetail[4];
        let isConfigHide = parseInt(fielddetail[10]);
        let isViewHide = parseInt(fielddetail[11]);
        let isMandatory = parseInt(fielddetail[16]);
        let defaultValue = fielddetail[19];
        if (fieldType !== "reference_entityid" && fieldType !== "reference_group" && fieldType !== "subtable") {
            if (fieldType === "reference") {
                let refDetail = fielddetail[35];
                let refformname = refDetail[1];
                dataRow[refformname + "_id"] = "";
            } else if (fieldType === "ComboBox") {
                defaultValue = defaultValue.split(",");
                defaultValue = defaultValue[1];
            }
            dataRow[fieldLabel] = defaultValue;
        }

    });
    return dataRow;
}

export async function loadDefaultFieldDetail(name, label) {
    let fielddetails = ["", "", name, label, ""];
    return fielddetails;
}

export function formatFileSize(bytes) {
    if (bytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = bytes / Math.pow(1024, i);
    return `${size.toFixed(2)} ${sizes[i]}`;
};

export async function getContactLinkedData(formname) {
    let params = {
        ENTITY: "WHATSAPPPROCESS",
        OPERATION: "GET_ALL_TAGS",
        FORMNAME: formname
    };
    let response = await requestApi.processServerRequest(params);
    let formOptions = [];
    if (response.issuccess) {
        let data = response.data;
        let { options } = data;
        formOptions = options;
    }
    return formOptions;
}

/**
 * Replace symbols with html elements
 * @param {String}  filename       - new create filename
 * @param {Array}   formData      - file data header and body
 * @param {String}  format         - format like csv, html and other
 */
export async function downloadFile(filename, formData, format = 'text/csv') {
    const blob = new Blob(formData, { type: format });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename; // Use the filename from the response
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export async function arrangeCustomSystemFields(fieldDetails) {
    let fieldMapper = {};
    let systemFields = [];
    let customFields = [];
    fieldDetails.map((fd) => {
        let isConfigHide = parseInt(fd[10]);
        let isCustomField = parseInt(fd[32]);
        if (!isConfigHide || isCustomField) {
            if (isCustomField) {
                customFields.push(fd);
            } else {
                systemFields.push(fd);
            }
        }
    });
    fieldMapper['system'] = systemFields;
    fieldMapper['custom'] = customFields;
    return fieldMapper;
}

export function getSelectedFormIds(selectedRows) {
    let formIds = [];
    if (selectedRows.length > 0) {
        let contactIds = selectedRows.map(row => row.table_74_id).join(","); // Join the contact IDs into a single string
        formIds = contactIds.split(",").map(Number);
    }
    return formIds;
}

export function getPerRecordOption() {
    const pageOptions = [
        { value: 5, label: "5" },
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" },
    ];
    return pageOptions;
}

export async function checkMicrophonePermission() {
    try {
        const permissionStatus = await navigator.permissions.query({ name: "microphone" });
        if (permissionStatus.state === "granted") {
            console.log("Microphone access is enabled.");
            return true;
        } else if (permissionStatus.state === "denied") {
            console.log("Microphone access is denied.");
            return false;
        } else {
            console.log("Microphone access is prompt (not yet granted or denied).");
            return true;
        }
    } catch (error) {
        console.error("Error checking microphone permissions:", error);
        return true;
    }
}

export async function getAllData(operation, formname, isAgency){
    let res = [];
    const params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: operation,
        FORMNAME: formname,
        isAgency: isAgency,
        isOtherApiReq: true,
    };

    try {
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            res = data;
        }
    } catch (error) {
        console.error("Error fetching dashboard summary:", error);
    }
    return res;
}

export function capitalizeFirstLetter(string) {
    if (!string) return ''; // Handle empty string case
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export async function getPermissions (appid="") {
    if(appid === ""){
        appid = getSessionValue("appid");
        let subAccountId = getSessionValue("subAccountAppid");
        if (subAccountId !== undefined && subAccountId !== null && subAccountId !== "") {
            appid = subAccountId;
        }
    }
    let params = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION": "GET_PERMISSIONS",
        'sub_accountId': appid,
    };
    let response = await requestApi.processServerRequest(params);
    return response;
}

export function getCurrentTime () {
    const now = new Date();
    const time = now.toLocaleTimeString("en-US", {
        hour12: false, // Disable AM/PM
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
    return time;
}
export function getTimeDiff(targetTime){
    const now = new Date();
    const differenceInMs = now.getTime() - targetTime.getTime();
    const timeDiffMinutes = Math.floor(differenceInMs / (1000 * 60));
    if (timeDiffMinutes >= 60 * 24 * 30) {
        const timeDiffMonths = Math.floor(timeDiffMinutes / (60 * 24 * 30));
        return (`${timeDiffMonths} month(s) ago`);
    } else if (timeDiffMinutes >= 60 * 24) {
        const timeDiffDays = Math.floor(timeDiffMinutes / (60 * 24));
        return (`${timeDiffDays} day(s) ago`);
    } else if (timeDiffMinutes >= 60) {
        const timeDiffHours = Math.floor(timeDiffMinutes / 60);
        return (`${timeDiffHours} hour(s) ago`);
    } else if(timeDiffMinutes < 60 && timeDiffMinutes >= 1){
        return (`${Math.floor(timeDiffMinutes)} minute(s) ago`);
    } else if(timeDiffMinutes < 1){
        return (`${Math.floor(differenceInMs / (1000))} second(s) ago`);
    }            
}
export async function checkIsAdmin(){
    let isAdmin = false;
    let userid = getLoginUserInfo('table_4_0_table_4_id');
    if(userid == "0" ||userid == "1" ){
        isAdmin = true;
    }
    return isAdmin;
}
export async function checkIsPageNotFound(key=""){
    let pageDisabled = false;
    let permissionRes = await getPermissions();
    let data = parseJSON(permissionRes.data);
    if(key != ""){
        pageDisabled = data[key] !== undefined && data[key] !== null && data[key] !== "" ? !data[key] : pageDisabled;
    }
    return pageDisabled;
}

export  async function getCustomeDominUrl(){
    let params = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION": 'GET_CUSTOME_DOMAIN_URL',
    };
    let url = "";
    let response = await requestApi.processServerRequest(params, "WebV2");
    if(response.issuccess){
        url = response.data;
    }
    return url;
}

export function formatToTwoDecimals (value){
    if (value === "") return ""; 
    const num = parseFloat(value);
    if (isNaN(num)) return value;
    return num % 1 === 0 ? num : num.toFixed(2);
};
export function getDeleteIcon(){
    let removeIcon = (document.body.className === 'dark-theme' || document.body.className.includes('dark-theme'))  ? darkDeleteIcon : deleteIcon;
    return removeIcon;
}
export function getWarningIcon(){
    let removeIcon = document.body.className === 'dark-theme' ? darkWarningIcon : warningIcon;
    return removeIcon;
}

export function getDarkThemeColor() {
    const theme = getGlobalTheme();
    return theme === "dark-theme" ? "#03a086" : "#034737";
}

export function convertUTCToTimeZone(utcDateString, timeZone) {
    const utcDate = new Date(utcDateString + "Z"); 
    const convertedDate = utcDate.toLocaleString("en-US", { timeZone });
    return convertedDate;
}

export default Appcommon;
