import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const Link = (props) => {
  const [favicon, setFavicon] = useState();

  useEffect(() => {
    if (props.icon !== "" && props.icon !== undefined && props.icon !== null) {
      setFavicon(props.icon);
    } else {
      //setFavicon(process.env.REACT_APP_FAVICON);
    }
    
  }, [props]);
  
  return (
    <div>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
    </div>
  );
};

export default Link;
