import React, { Fragment, useState } from "react";
import Calpng from "../../assets/utils/images/Intagration image/cal.png"
import cx from "classnames";
import { Button, Col, Row} from "reactstrap";
import { cardData } from './Constants';

const Cal = (props) => {
    let {isInstalation,buttonValue,unInstall} = props;   
    const [carddata,setData] = useState(cardData);
    let key = '';
    let foundData = carddata.find((data) => data.heading === "Cal.com");
    if (foundData) {
     key = foundData.component.button.key;
    }    
    const onOpenModal = (type,openModule = false) => {
            isInstalation(type,openModule,'Cal.com',"Calendar");
    }

    return (
        <Fragment>
            <div className="agent-details">
                {
                    <Fragment>
                        <div className="ghl-heading">
                            <div className="ghl-details">
                                <Row>
                                <Col md={1}>
                                <span className="card-image-status">
                                    <img src={Calpng} className="intagration-card-detail-img"/>
                                </span>
                                </Col>
                                <Col md={8} className="p-1">
                                <span className="heading">
                                    Cal.com
                                </span>
                                <span className="tag">
                                    Calendar
                                </span>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                {!buttonValue.includes(foundData.heading) ? (<Button
                                    name={"cancel"}
                                    className={cx("badge-theme badge-btn m-4")}
                                    onClick={(e) => { onOpenModal(key,true) }}
                                >
                                    <i class="fi fi-br-plus-small"></i>
                                    {<span>{"Install App"}</span>}
                                </Button>):(<Button
                                    name={"cancel"}
                                    className={cx("badge-theme badge-btn m-4")}
                                    onClick={(e) => { unInstall(key) }}
                                >
                                    <i class="fi fi-br-plus-small"></i>
                                    {<span>{"Uninstall App"}</span>}
                                </Button>)}
                                </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="card-fields">
                            <span className="field-name">Website</span>
                            <span>
                                <a href="https://cal.com" target="_blank">https://cal.com</a>
                            </span>
                        </div>
                        <div className="card-fields">
                            <span className="field-name">Overview</span>
                            <span className="subheading">The Voicemetrics integration with Cal.com transforms scheduling into an effortless experience for your team and customers. By combining OneInbox’s advanced AI calling capabilities with Cal.com’s flexible calendar management, businesses can fully automate appointment booking, rescheduling, and reminders. This integration lets you simplify workflows and increase efficiency by enabling AI agents to handle scheduling tasks seamlessly, even in high-volume scenarios.'</span>
                        </div>
                        <div className="card-fields">
                            <span className="field-name">Key Benefits</span>
                            <span className="subheading ver-2px">
                                <span className="col-pri"> Automated Scheduling and Rescheduling </span>
                                Free up valuable time by allowing our AI-powered agents to take over appointment bookings and rescheduling tasks. OneInbox integrates with Cal.com to check real-time availability and instantly confirm bookings on your behalf.                                <span className="col-pri"> Improved Accessibility </span>
                                Offer round-the-clock booking and scheduling support, ensuring customers can book appointments at any time.
                                <span className="col-pri"> Enhanced Customer Experience </span>
                                Provide a smoother, faster experience by offering 24/7 booking capabilities. OneInbox AI agents can assist customers anytime, checking availability and scheduling appointments within seconds.                            </span>
                                <span className="col-pri"> Real-Time Syncing </span>
                                All appointments are automatically synced with your Cal.com calendar, ensuring you and your team have the latest information without needing manual updates.
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
        
    )
}

export default Cal;