import React, { Fragment, useState,useEffect } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Row,Col, Button, Label,Card,CardBody } from "reactstrap";
import requestApi from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import {  handleErrorResponse, showToast,parseJSON,getPermissions, translateContent, getDarkThemeColor } from '../../Common/Appcommon';
import Switch from "react-switch";
import { defaultOption,permissionsHeader } from './constants';


const Permissions = (props) => {
    let { accountInfo } = props;
    const { handleSubmit } = useForm();
    const [inputData,setInputData] = useState({});
    const [modalHeaderDetails, setHeaderDetails] = useState({ header: "", description: "" });
    const [showBtn ,setShowBtn] = useState(false);


    useEffect(async () => {
        const headerDetails = permissionsHeader;
        setHeaderDetails({
            header: headerDetails !== undefined && headerDetails !== null && headerDetails[0]?.header || "",
            description: headerDetails !== undefined && headerDetails !== null && headerDetails[0]?.description || ""
        });
        await getCustomerPermissions();
    }, []);

    const handleInputChange = (e,value, type) => {
        let tmpInputData = { ...inputData };
        if (type === "Switch") {
            if(value === "contact_allowed" || value === "insights_allowed"){
                tmpInputData[value] = e; 
                let key = value === "contact_allowed" ? "manage_contacts" : "manage_insights";
                const options = defaultOption["Sub Account Permissions"];
                const optionGroup = options[key];
                optionGroup.map((item) => {
		if (item.name !== "assistant_allowed" && item.name !== "create_number") {
                    tmpInputData[item.name] = e;
                    }
                    //tmpInputData[item.name] = e;
                });
            }
            tmpInputData[value] = e; 
        }else {
            tmpInputData[e.target.name] = e.target.value;
            if(value.includes("limit") && (e.target.value == undefined || e.target.value == null || e.target.value == "")){
                tmpInputData[e.target.name] = 1;
            }
        }
        setInputData(tmpInputData);
        setShowBtn(true);
    };
    const handleIncrement = (name) => {
        setInputData((prevState) => ({
            ...prevState,
            [name]: (parseInt(prevState[name]) || 0) + 1, 
        }));
    };

    const handleDecrement = (name) => {
        setInputData((prevState) => ({
            ...prevState,
            [name]: prevState[name] > 1 ? parseInt(prevState[name]) - 1 : 1,
        }));
    };

    const getCustomerPermissions = async () => {
        let {data_row} = accountInfo;
        let response = await getPermissions(data_row.appid);//await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = parseJSON(response.data);
            if(data !== undefined && data !== null && data !== "" &&  Object.keys(data).length > 0){
                setInputData((data));
            }else{
                const updatedInputData = { ...inputData };
                Object.keys(defaultOption).map((category) => {
                    const options = defaultOption[category];
                    Object.keys(options).map((key) => {
                        const optionGroup = options[key];
                        optionGroup.map((item) => {
                            updatedInputData[item.name] = item.type === "Switch" ? true : 1;
                        });
                    });
                });
                setInputData(updatedInputData);
            }  
        }
    }

    const savePermissions = async () => {
        let {data_row} = accountInfo;
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "SAVE_PERMISSIONS",
            "permission_details": inputData,
            "sub_accountId":data_row.appid,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast(translateContent("vm.subaccount.permissions.save.msg"))
        }else{
            handleErrorResponse(translateContent("vm.subaccount.permissions.error.msg"))
        }
    }
    

    return(
        <Loading showLoader={false} mainContent={
            <Fragment>
                <form id='permissions-form' onSubmit={handleSubmit(savePermissions)}>
                    <div className='mb-4'>
                        <div className={`d-flex justify-end me-5  hide`}>
                            <Button  type={'submit'} className={"theme-bg-g"}>
                                {"Save"}
                            </Button>
                        </div>
                        <div className='custom-save-btn'>
                            <CardBody className='agency-details'>
                                
                                {
                                    Object.keys(defaultOption).map((category,index) => {
                                        const options = defaultOption[category];
                                        const optionKeys = Object.keys(options);
                                        return (
                                            <Card key={category} className="col-md-12">
                                                <CardBody>
                                                <Row md={12} className="">
                                                    <Col md={6}>
                                                        <h5 className="provider-header mt-2">{category}</h5>
                                                    </Col>
                                                    <Col md={6} className="d-flex justify-end">
                                                        <Button type="submit" className={`${showBtn ? 'theme-bg-g btn btn-dark m-1' : "hide"}`} >Save & Publish</Button>
                                                    </Col>
                                                </Row>
                                                <Row className='pb-3 bb-1'>
                                                    <Col className='header-desc'>Customize the menus and features accessible to this sub-account.</Col>
                                                </Row>
                                                </CardBody>

                                                {optionKeys.length > 0 && (
                                                    <>
                                                    <div className='permission-row'>
                                                        <Row md={12} className='bb-1'>
                                                            {optionKeys.slice(0, 2).map((key, index) => (
                                                                <Col md={6} key={key} className={`${optionKeys[index] === "manage_contacts" ? '': 'permission-switch-desc'}`}>
                                                                {options[key].map((option, optionIndex) => (
                                                                    <Row className={option.name === "assistant_allowed" || option.name === "create_number" ? '' : 'hide'}>
                                                                    <Col md={6} className={option.name === "create_number" ? 'ps-3':''}>
                                                                        <h5 className="provider-header mb-3">{option.label}</h5>
                                                                    </Col>
                                                                    <Col md={5} className={`d-flex justify-end ${option.name !== "create_number" ? 'ms-2' : 'permission-switch'}`}>
                                                                        {option.type === "Switch" && (
                                                                        <Switch 
                                                                            height={20} 
                                                                            width={40} 
                                                                            onColor={getDarkThemeColor()}
                                                                            onChange={(e) => handleInputChange(e, option.name, 'Switch')}
                                                                            name={option.name}
                                                                            checked={inputData[option.name] || false}
                                                                        />
                                                                        )}
                                                                    </Col>
                                                                    </Row>
                                                                ))}
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                        
                                                    <Row md={12} className={"pt-2 ps-3 bb-1"}>
                                                        {optionKeys.map((key,index) => (
                                                            <Col md={6} key={key} className={`${optionKeys[index] === "manage_contacts" ? 'br-1': 'ps-5'}`}>
                                                                {options[key].map((option, optionIndex) => (
                                
                                                                    <Row key={optionIndex} className={`${optionKeys[index] === "manage_contacts" ? (option.name === "assistant_allowed" ? 'hide' : '') : (option.name === "create_number" ? 'hide' :"")} mb-3`}>
                                                                        {option.name === "contact_allowed" || option.name === "insights_allowed" ? (
                                                                            <>
                                                                                <Col md={6}>
                                                                                    <h5 className="provider-header mb-3">{option.label}</h5>
                                                                                </Col>
                                                                                <Col md={5} className="d-flex justify-end">
                                                                                    {option.type === "Switch" && (
                                                                                        <Switch 
                                                                                            height={20} 
                                                                                            width={40} 
                                                                                            onColor={getDarkThemeColor()}
                                                                                            onChange={(e) => handleInputChange(e, option.name, 'Switch')}
                                                                                            name={option.name}
                                                                                            checked={inputData[option.name] || false}
                                                                                        />
                                                                                    )}
                                                                                </Col>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Col md={1}>
                                                                                    {option.type === "Switch" && (
                                                                                        <Switch 
                                                                                            height={20} 
                                                                                            width={40} 
                                                                                            onColor={getDarkThemeColor()}
                                                                                            onChange={(e) => handleInputChange(e, option.name, 'Switch')}
                                                                                            name={option.name}
                                                                                            checked={inputData[option.name] || false}
                                                                                            disabled={(key === "manage_contacts" && !inputData['contact_allowed']) || 
                                                                                                    (key === "manage_insights" && !inputData['insights_allowed'])}
                                                                                        />
                                                                                    )}
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <Label className="permission-desc ps-2">{option.label}</Label>
                                                                                </Col>
                                                                            </>
                                                                        )}
                                                                        
                                                                    </Row>
                                                                   
                                                                ))}
                                                            </Col>
                                                           
                                                           
                                                        ))}
                                                        <Col>
                                                            <br></br>
                                                        </Col>
                                                    </Row>
                                                    </>
                                                )}
                                            </Card>
                                        );
                                    })
                                }
                                
                            </CardBody>
                            </div>
                        {/* </Card> */}
                    </div>
                </form>
            </Fragment>
        }/>
    )
}
const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
