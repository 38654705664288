import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col } from "reactstrap";
import Common from "../Common";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { cardData } from './Constants';
import { isMobile, showToast, confirmBox } from "../../Common/Appcommon";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import requestApiService from "../../services/requestApi.service";
import GoogleCalendar from "./GoogleCalendar";
import Cal  from './Cal.js';
import Twilio from './Twilio.js';
import Email from './Email.js';
import GoHighlevel  from './GoHighlevel.js';
import ModalContent from './IntergationModal.js';

const Integrations = (props) => {
  const { type } = useParams();
  const [rows, setRows] = useState([]);
  const [DetailsKey, setDetailsKey] = useState('');
  const [IntegrationsKey, setIntegrationsKey] = useState({ apiKey: '', authToken: '', name: '', password: '',url: '', port: '', protocol: '',sender: '', fromaddress: '',});
  const [errorMessage,seterrorMessage] = useState('');
  const [buttonStatusValues,setButtonStatusValues] = useState([]);
  const [installedButton,setShowInstalledButton] = useState([]);
  const [setBtn,setInstallBtn] = useState([]);
  const [showButtonLoading ,setButtonLoading] = useState('');
  const [googleIntegration, setGoogleIntegrations] = useState(false);
  const [name,setName] = useState('');
  const [isModalOpen, setShowModule] = useState(false);
  const mobile = isMobile();
  const getTitleBody = (showDetailed = false) => {
    return showDetailed ? "" : <div></div>;
  };

  useEffect(async () => {
    setRows(cardData);
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "CHECK_INTEGRATIONS_INSTALATION",
        FORMNAME: 'vm_integrations',
    }
    let formresponse = await requestApiService.processServerRequest(params,"WebV2");
    if (formresponse.issuccess) {
        let data = formresponse.data;
        setShowInstalledButton(data);
    }
  }, [setBtn]);
  const openModule = (Modulekey) => {
    window.location.hash = `#/integrations/${Modulekey}`;
  }
  const onBackAction = () => {
    window.location.hash = `#/integrations/`;
  }
  
  const openInstalation = (key,show = false,name,subName) => {
    if(key !== 'google-calendar'){
        setButtonStatusValues(key);
        setDetailsKey(key);
        setName(name);
        setShowModule((prevState) => !prevState);
    }else{
        setGoogleIntegrations(true);
        IntegrationsDetailContant();
    }
  }

  const handleInputChange = (e, field) => {
    let value; // = e.target.value;
    if (e && e.value) {
        value = e.value;
    } else if (e && e.target) {
    // For regular Input field, the value is inside `e.target.value`
    value = e.target.value;
    }

    setIntegrationsKey((prev) => ({
        ...prev,
        [field]: value
    }));
  };

  const connectAPI = async (keyvalue,buttonStatusValues) => {
    if(keyvalue !== '' && keyvalue !== null  & keyvalue !== undefined){
        setButtonLoading(true);
        let namevalue = name;
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "CONNECT_INTEGRATIONS",
            FORMNAME: 'vm_integrations',
            name : namevalue,
            details : {type: buttonStatusValues,data: keyvalue} ,//{type: buttonStatusValues,api_key: keyvalue,},         
        };
        let formresponse = await requestApiService.processServerRequest(params,"WebV2");
        if (formresponse.issuccess) {
            setInstallBtn('');
            closeModal();
            showToast("Connected successfully.");
        }else if(formresponse.errormsg){
            seterrorMessage(formresponse.errormsg);
            setButtonLoading(false);
        }
    }else{
        seterrorMessage('API key is required.');
        setButtonLoading(false);
    }
    setButtonLoading(false);
  }

  const unInstallIntegrations = async (namevalue) => {
    let confirm = await confirmBox("Are you sure want to Uninstall " +namevalue+ "?", "Uninstalling the " +namevalue+ " app will also delete all the actions associated with your account.", 'warning', 'Uninstall', 'Cancel');
    if (!confirm) {
      return;
    }
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "UNINSTALL_INTEGRATIONS",
        FORMNAME: 'vm_integrations',
        name : namevalue,
    };
    let formresponse = await requestApiService.processServerRequest(params,"WebV2");
    if (formresponse.issuccess) {
        setInstallBtn(buttonStatusValues);
        showToast("UnInstalled successfully.");
    }
  }

  const closeModal = () =>{
    setShowModule((prevState) => !prevState);
    setIntegrationsKey('');
    seterrorMessage('');
    setButtonLoading(false);
  }

 
const IntegrationsMainContant = () => {
    if(type === '' || (type === undefined && !googleIntegration)){
        return(
            <Fragment>
                <div className="integration-card">
                {<Row>
                        {rows.map((card, index) => (
                            <Col key={index} className="p-1 mb-3" md={3} xs={0} sm={0} lg={0}>
                                <Card className="card-br">
                                    <CardBody>
                                        <Row className={`${!mobile ? "" : "mb-4"}`}>
                                            {!mobile ? (
                                                <>
                                                    <Col md={3}>
                                                        <img src={card.img} alt={card.heading} className="intagration-card-img" />
                                                    </Col>
                                                    <Col md={9}>
                                                        <div>
                                                            <span className="intagration-card-heading">
                                                                {card.heading}
                                                            </span>
                                                        </div>
                                                        <span className="intagration-card-subheading">
                                                            {card.subheading}
                                                        </span>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="intagration-card-description">
                                                            {card.discription}
                                                        </div>
                                                    </Col>
                                                    {card.component ? (
                                                        <>
                                                            <Col md={12} className="justify-center d-flex">
                                                            <div className="justify-center d-flex">
                                                                <Button 
                                                                    onClick={() => openModule(card.component.button.key)} 
                                                                    className="custom-btn-1 btn btn-dark p-1"
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} /> {card.component.button.details}
                                                                </Button>
                                                            </div>
                                                            {/* </Col>
                                                            <Col md={6} className="justify-start d-flex p-1"> */}
                                                            <div className="justify-center d-flex ms-3">
                                                                {!installedButton.includes(card.heading) ? ( //component.button.key
                                                                    <Button 
                                                                        onClick={() => openInstalation(card.component.button.key,true,card.heading,card.subheading)} 
                                                                        className="custom-btn-1 btn btn-dark p-2"
                                                                    >
                                                                        <FontAwesomeIcon icon={faPlus} /> {card.component.button.install}
                                                                    </Button>) : (
                                                                        <div className="intagration-comingsoon">
                                                                        Installed
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <Col md={12} className="text-center">
                                                            <div className="intagration-comingsoon">
                                                                Coming Soon
                                                            </div>
                                                        </Col>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {/* <Col className="w-25"> */}
                                                    <div className="mobile-img">   
                                                        <img src={card.img} alt={card.heading} className="intagration-card-img" />
                                                        </div> 
                                                    {/*  </Col> */}
                                                    <Col md={9} className="mt-1-1 ms-1 w-75" style={{ height: "95px" }}>
                                                        <div className="card-fields d-grid justify-start ms-2">
                                                            <span className="intagration-card-heading">
                                                                {card.heading}
                                                            </span>
                                                            <span className="intagration-card-subheading">
                                                                {card.subheading}
                                                            </span>
                                                        </div>
                                                        <Row className="p-1">
                                                            <div className="intagration-card-description p-3 mb-4">
                                                                {card.discription}
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mt-4">
                                                        <Col className="w-50 ms-2">
                                                            {card.component ? (
                                                                <Button 
                                                                onClick={() => openModule(card.component.button.key)} 
                                                                className="custom-btn-1 btn btn-dark p-1"
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} /> {card.component.button.details}
                                                                </Button>
                                                            ) : (
                                                                <div className="intagration-comingsoon mb-2 ms-5">
                                                                    Coming Soon
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col className="w-50 ms-2">
                                                            {card.component ? (
                                                                <>
                                                                {!installedButton.includes(card.heading) ? ( //component.button.key
                                                                    <Button 
                                                                        onClick={() => openInstalation(card.component.button.key,true,card.heading,card.subheading)} 
                                                                        className="custom-btn-1 btn btn-dark p-2"
                                                                    >
                                                                        <FontAwesomeIcon icon={faPlus} /> {card.component.button.install}
                                                                    </Button>) : (
                                                                        <div className="intagration-comingsoon">
                                                                        Installed
                                                                        </div>
                                                                    )
                                                                }
                                                                </>
                                                               /*  <Button 
                                                                    onClick={() => openInstalation(card.component.button.key,true,card.heading,card.subheading)} 
                                                                    className="custom-btn-1 btn btn-dark p-2"
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} /> Install app
                                                                </Button> */
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                </Row>}
            </div> 
            </Fragment>
        )
    }
}
 
const IntegrationsDetailContant = () => {
        if((type === 'google-calendar') || (type === undefined && googleIntegration === true)){
            return(<GoogleCalendar install={googleIntegration} installedButton={setInstallBtn}></GoogleCalendar>); // install={googleIntegration}
        }else if(type === 'cal.com'){
            return(<Cal isInstalation={openInstalation} buttonValue={installedButton} unInstall={unInstallIntegrations} ></Cal>);
        }else if(type === 'twilio'){
            return(<Twilio isInstalation={openInstalation} buttonValue={installedButton} unInstall={unInstallIntegrations}></Twilio>);
        }else if(type === 'email'){
            return(<Email isInstalation={openInstalation} buttonValue={installedButton} unInstall={unInstallIntegrations}></Email>);
        }else if(type === 'highlevel'){
            return(<GoHighlevel isInstalation={openInstalation} buttonValue={installedButton} unInstall={unInstallIntegrations}></GoHighlevel>);
        }
}

    return (
        <Fragment>
            <Common
                title="Integrations"
                titleContent={type === '' || type === undefined ? <span>Integrations</span> : <a className="cursor-pointer" onClick={onBackAction}><span className="d-flex align-center"><i className="fi fi-br-arrow-left pr-1"></i><span>Integrations</span></span></a>}
                titleBody={getTitleBody()}
                mainContent={
                            <div className="w-100">
                            {IntegrationsMainContant() ?? IntegrationsDetailContant()} 
                            {isModalOpen && 
                                <ModalContent 
                                    isModalOpen={isModalOpen}
                                    closeModal={closeModal}  
                                    DetailsKey={DetailsKey} 
                                    IntegrationsKey={IntegrationsKey} 
                                    handleInputChange={handleInputChange} 
                                    errorMessage={errorMessage} 
                                    showButtonLoading={showButtonLoading} 
                                    connectAPI={connectAPI} 
                                    buttonStatusValues={buttonStatusValues}
                                    buttonValue={installedButton} 
                                />
                            }
                            </div>
                        }
            />
        </Fragment>
    );
};


const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
