import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { Form, Row, Col, Label, Input, Container, Button } from "reactstrap";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import PopOverItem from "../../Common/PopOverItem"
import Loading from '../../Common/Loading';
import { checkIsAdmin, getSessionValue } from '../../Common/Appcommon';
import { useTheme } from "../../Context/ThemeContext";

const AgentMainContent = (props) => {
    const { rowData: initialRowData, fieldNames,voiceList,isLoading} = props;
    const { handleSubmit, control, reset, formState: { errors } } = useForm();
    const isAllowEdit = getSessionValue('isAllowEditAgent') === true || getSessionValue('isAllowEditAgent') === "true" ? true : false;
    const [isAdmin,setAdmin] = useState(true);
    useEffect(async () => {
        reset(initialRowData);
        let adminProfile = await checkIsAdmin();
        setAdmin(adminProfile);
    }, [initialRowData, reset]);
    const voiceId = initialRowData[fieldNames["voice_id"]];
    const voice = voiceList.flat().find(voice => voice.Voice_Id === voiceId);
    const name = voice ? voice.Name : '';
    const {theme} = useTheme();
    const isDark = theme === 'dark-theme'? true : false;
    const configViewFieldSave = [
        {
            label: "AI Agent Name",
            name: "Agent Name",
            datalabel: fieldNames['Agent_Name'],
            type: "text",
            description: "",
            placeholder: "Name Here",
            required: true,
            isDisabled: !isAllowEdit,
        },
        {
            label: "Voice",
            name: "Voice",
            datalabel: fieldNames['voice_id'],
            type: "text",
            description: "Voice.",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off"
        },
        {
            label: "First Message",
            name: "Call Start Message",
            datalabel: fieldNames['Call_Start_Message'],
            type: "textarea",
            description: "The first message that the AI Agent will say.",
            placeholder: "First Message Here",
            required: true,
            isDisabled: !isAllowEdit,
        },
        {
            label: "System Prompt",
            name: "System Prompt",
            datalabel: fieldNames['System_Prompt'],
            type: "textarea",
            description: "Give AI agents intelligence with information (context, role, personality, instructions, etc.)",
            placeholder: "System Prompt Here",
            required: false,
            isDisabled: !isAllowEdit,
        }
    ];

    const onSubmit = (data) => {
        props.onSubmit(data);
    };

    const handleChange = (e) => {
        let {name, value} = e.target;
        props.onChange(name, value)
    };

    const openModel = (e) => {
        props.modalOpen();
    };
    return (
        <Fragment>
            <Container fluid>
                <div className='agent-config-container'>
                    <div className='agent-config-form'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {configViewFieldSave.map((field, index) => {
                                const { label, name, datalabel, type, placeholder, required, description, isDisabled,autoComplete } = field;
                                if(!isAllowEdit && name == "Voice"){
                                    return;
                                }else if(name == "Voice"){
                                    return;
                                }
                                return (
                                    <Row key={index} className="mb-3">
                                        <Col md="12" lg="12">
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                                {
                                                    description &&
                                                    <PopOverItem 
                                                        id={index}
                                                        body={description}
                                                        theme={isDark}
                                                    />
                                                }
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type={type}
                                                        placeholder={placeholder}
                                                        value={name === "Voice" ? (voice ? voice.Name : '') : initialRowData[datalabel]}
                                                        className={`${errors[name] ? 'is-invalid' : (name === "Call Start Message" ? "agent-greeting" : '')} ${name === "Voice" ? "cursor-poiter" : ''}`}
                                                        onChange={e => {handleChange(e)}}
                                                        onClick={name === "Voice" ? e => openModel(e) : undefined} // Conditionally add onClick
                                                        disabled={isDisabled}
                                                        autoComplete= {autoComplete}
                                                    />
                                                )}
                                            />
                                            {errors[name] && (
                                                <em className="text-danger">
                                                    {errors[name].message}
                                                </em>
                                            )}
                                        </Col>
                                    </Row>
                                );
                            })}

                            {isAdmin && isAllowEdit &&
                                <Row>
                                    <div className='col-2'>
                                    <Loading 
                                        showLoader={isLoading} 
                                        mainContent = {
                                            <Button className="agent-save-btn mb-2 btn-icon submit-btn btn-background" type="submit">
                                                Save
                                            </Button>}
                                    />
                                    </div>
                                </Row>
                            }
                           
                        </Form>
                    </div>
                </div>
            </Container>
        </Fragment>
    );
};




const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentMainContent);
