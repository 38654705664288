import React, {Component, Fragment} from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody} from "reactstrap";
// import info from "../Components/Agents/assets/images/info.svg";
import cx from "classnames"; 

const PopOverItem = (props) =>{
    
    let placement = "";
    let title = "";
    let body = "";
    let className = "";
    let isDark = "";
    if(props.placement !== ""){
        placement = props.placement;
    }
    if(props.title !== ""){
        title = props.title;
    }

    if(props.body !== ""){
        body = props.body;
    }
    if(props.className !== ""){
        className = props.className;
    }
    if(props.theme !== ""){
        isDark = props.theme;
    }
    return (
        <Fragment>
                  <i id={"Popover-" + props.id} className={cx({ "dark-popover-info-btn": isDark }, { "popover-info-btn": !isDark },"fi fi-ss-info")}/> 
                  <UncontrolledPopover placement={placement} trigger="legacy" fade={false} target={"Popover-" + props.id}>
            {
                title !== "" && 
                <PopoverHeader>{title}</PopoverHeader>
            }
            { 
                body !== "" && 
                <PopoverBody>
                    {body}
                </PopoverBody>
            }
            </UncontrolledPopover>
        </Fragment>
    );
}

export default PopOverItem;