import React, { Fragment, useEffect } from "react";
import gCalendar from "./assets/images/google_calendar.svg"
import cx from "classnames";
import { Button } from "reactstrap";
import requestApiService from "../../services/requestApi.service";
import { useParams } from "react-router-dom";

const CallBack = (props) => {
    const { param } = useParams();
    useEffect(() => {
            (async () => {
                await processCallbackData(param);
            })();
            return () => {
                // this now gets called when the component unmounts
            };
        }, []);

    const processCallbackData = async (param) => {
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_OAUTH",
            input : param
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            if(data === "Google"){
                window.location.href = "#/integrations/google-calendar";
            }
        }
    }

    return (
        <Fragment>
            <div>{param}</div>
        </Fragment>
    )
}

export default CallBack;