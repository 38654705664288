import React, { Fragment, useEffect, useState } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from "../Common";
import Filter from "./Filter";
import { Col, Row, Button } from "reactstrap";
import CallAnalysisSummary from "./CallAnalysisSummary";
import VerticalBarChat from "./VerticalBarChat";
import "./index.css";
import requestApi from "../../services/requestApi.service";
import { WinLoseChart,CallAnalyticsTypeOption } from "./constant";
// import { subDays } from "date-fns";
import { isMobile } from "../../Common/Appcommon";

const CallOutcome = () => {
    const [chartData, setChartData] = useState([]); 
    const [agent, setAgent] = useState("all_agent");
    const [callType, setCallType] = useState("goal_complete");
    const [demo, setDemo] = useState(false);
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 29);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999); 
    const [dateFilter, setDateFilter] = useState({
        Start_DateTime: [startDate, endDate],
        noofdays: 30,
    });
    const [showLoader, setShowLoader] = useState(true);
    const mobile = isMobile();
    const [chartValues, setDashboardChart] = useState(['bar']);

    const generateParams = (chartConfig) => {
        return {
            ENTITY: "AIPROCESSOR",
            OPERATION: chartConfig.operation,
            FORMNAME: "table_37",
            FILTEROPTION: [dateFilter], 
            agent: agent,
            demo:demo,
            callType:callType, 
            isOtherApiReq: true,
        };
    };

    const getContent = async () => {
        const params = generateParams(WinLoseChart[0]);
        setShowLoader(true);
        try {
            const response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                const data = response.data;
                if (data && data.length > 0) {
                    setChartData(data); 
                }
            }
            setShowLoader(false);
        } catch (error) {
            console.error("Error fetching chart data:", error);
            setShowLoader(false); 
        }
    };

    const handleAgentValue = (selectedAgent) => {
        setAgent(selectedAgent.value); 
    };

    const handleDateValue = (selectedDate) => {
        setDateFilter(selectedDate); 
    };

    const handleCallTypeValue = (selectedCallType) =>{
        setCallType(selectedCallType.value) 
    }

    const handleDemo = (ischecked) =>{
        setDemo(ischecked);
    }

    const handleButtonClick = (graphtype) => {
        setDashboardChart((prev) => {
            const updatedValues = [...prev];
            updatedValues[0] = graphtype; // Set the type for the specific index
            return updatedValues;
        });
    };

    const setMainContent = () => {
        return (
            <Col md={12}>
                <Row className="ms-0 me-0 gx-0 gy-0">
                    {mobile &&
                        getTitleBody()
                    }
                    <Col md={7} className="mt-3"> 
                        <div className="justify-content-end d-flex me-3">  
                            <Button key="area" className={chartValues[0] === "area" ? "btn-1 m-1 theme-bg-g" : "custom-btn-1 m-1"} color="dark" onClick={() => handleButtonClick('area')}>
                                <div className="dashboard-chart-icon">
                                    <i className="fi fi-br-chat-arrow-grow"></i>
                                </div>
                            </Button>
                            <Button key="bar" className={chartValues[0] === "bar" ? "btn-1 m-1 theme-bg-g" : "custom-btn-1 m-1"} color="dark" onClick={() => handleButtonClick('bar')}>
                                <div className="dashboard-chart-icon">
                                    <i className="fi fi-ss-chart-simple"></i>
                                </div>
                            </Button>  
                        </div>                           
                        <VerticalBarChat 
                            defaultChartConfig={WinLoseChart}
                            chartData={chartData} 
                            Loader={showLoader} 
                            type = {chartValues[0]}
                        />
                    </Col>
                    <Col md={5}>
                        <div className="mt-4">
                            <CallAnalysisSummary
                                lg={6}
                                callType={callType}
                                agent={agent} 
                                dateFilter={dateFilter}
                                demo={demo} 
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        );
    };

    const getTitleBody = () =>{
        let limitedvalue = CallAnalyticsTypeOption.slice(3,5);
        return (
            !demo &&
            <Filter
                getAgentvalue={handleAgentValue} 
                getdate={handleDateValue}
                getcallType={handleCallTypeValue} 
                CallTypeOption = {limitedvalue} 
                needCallFilter={true}             
                needAgentFilter={true}           
                needDateFilter={true}
                needDemo={true}
                getDemo={handleDemo}
                setDemo={demo}
            />
        );
    }
    useEffect(() => {
        getContent();
    }, [dateFilter, agent,callType,demo]);

    return (
        <Fragment>
            <Common
                demoPopup = {
                    demo
                }
                demoHandler = {
                    handleDemo
                }
                titleBody={
                    !mobile &&  getTitleBody()
                }
                title={
                    "Goal Conversion"
                }
                titleContent={
                    "Goal Conversion"
                }
                mainContent={setMainContent()}
                titleKey={"won_lost_enabled"} 
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallOutcome);
