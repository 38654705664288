import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { subDays } from 'date-fns';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import Common from "../Common";
import DateFilter from "../../Common/DateFilter";
import { Container } from "reactstrap";
import Summary from "../../Components/Dashboard";
import { isAgentAccount } from "../../Common/Appcommon";

class ProfitReport extends Component{
    isAgency = isAgentAccount();

    constructor(props) {
        super(props);
        const startDate = subDays(new Date(), 30); // Default to 7 days ago
        const endDate = new Date(); 
        this.state = {
            dateFilter: {
                Start_DateTime: [startDate, endDate],
                noofdays: 7
            },
            showLoader : false,
            summary : [],
            Loader : false,
        };
    }

    getTitleContent = () => {
        return(
            <>
                <span>
                    Profit Report
                </span>
            </>
        )
    }

    handleDateChange = (startDate, endDate) => {
        if (startDate && endDate) {
            let dateobj = {};
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            const timeDifference = endDate.getTime() - startDate.getTime();
            const numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
            dateobj['Start_DateTime'] = [startDate, endDate];
            dateobj['noofdays'] = numberOfDays;
            this.setState({ 
                dateFilter: dateobj, 
                dashBoardData: [], 
                showLoader: true 
            }, async () => {
                try {
                    // Run all the async functions in parallel
                } catch (error) {
                    console.error('Error during date change operations:', error);
                } finally {
                    // Ensure the loader is turned off after all operations are complete
                    this.setState({ showLoader: false });
                }
            });
        }
    }

    titleBodyContent = () => {
        return(
            <>
                <DateFilter 
                    handleDateChange={this.handleDateChange}
                    defaultSelectIndex={3}
                />
            </>
        )
    }

    render(){
        return(
            <Fragment>
                <Common
                    title={"Profit Report"}
                    titleContent={
                        this.getTitleContent()
                    }
                    titleBody={
                        this.titleBodyContent()
                    }
                    mainContent={
                        <Fragment>
                            <Container fluid>
                                <Summary isAgency={this.isAgency}/>
                            </Container>
                        </Fragment>
                    }
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitReport);
