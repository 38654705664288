import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    Row,
    Col,
    Container,
    Button,
    Card,
    CardBody,
    Input,
    Label,
    ModalBody,
    ModalHeader,
    Modal,
    ModalFooter
} from "reactstrap";
import Common from "../Common";
import ListTable from "../../Common/ListTable";
import requestApiService from "../../services/requestApi.service";
import { confirmBox, getSessionValue, prepareCustomTableData, showToast, translateContent } from "../../Common/Appcommon";
import { useForm ,Controller} from "react-hook-form";
import Loading from "../../Common/Loading";
import CKEditorComponent from "./CKEditorComponent";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import CratioMention from "../Common/CratioFields/CratioMention";
import Select from "react-select";
import sendEmailIcon from '../../assets/utils/images/newsletter-subscribe.png';

const EmailTemplates = (props) => {
    const [templateData,setTemplateData] = useState([]);
    const [templateColumns,setTemplateColumns] = useState([]);
    const [isModalOpen,setIsModalOpen] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset, trigger } = useForm();
    const [inputData, setInputData] = useState({});
    const [showButtonSaveLoading, setShowSaveButtonLoading] = useState(false);
    const [showButtonTestLoading, setShowButtonTestLoading] = useState(false);
    const [editorData, setEditorData] = useState(null);
    const [selectedMacro,setSelectedMacro] = useState([]);
    const [searchString,setSearchString] = useState("");
    const [macroFieldItems,setMacroFieldItems] = useState([]);
    const [curDropDown,setCurDropDown] = useState([]);
    const [showLoading,setShowLoading] = useState(false);
    // const [categoryDrop,setCategoryDrop] = useState([]);
    let categoryDrop = [
        {value: '', label: 'All'},
        { value: '_agent', label: 'Agent' },
        { value: '_customer', label: 'Customer' },
    ];
    useEffect (() =>{
        getTemplateList();
    },[])

    useEffect(() => {
        if (curDropDown !== null) {
            getTemplateList();
        }
    }, [curDropDown]);

    useEffect(() => {
        if (inputData && inputData.content) {
            setEditorData(cleanHTML(inputData.content));
        }
    }, [inputData]); 

    useEffect(() => {
        if (inputData && inputData.name) {
            let curMacro = macroFieldItems[inputData.name]; // Now it will have updated data   
            const fieldItems = curMacro?.fields?.split(",") || [];
            const nameItems = curMacro?.name?.split(",") || [];
            const macroItems = fieldItems.map((item, index) => ({
                id: item,
                name: nameItems[index]?.trim() || item.replace(/^\$|}$/g, '').trim() // Fallback to cleaned ID if no name exists
            }));
            setSelectedMacro(macroItems);
        }
    }, [macroFieldItems, inputData]);

    
    const getTemplateList = async () =>{
        setShowLoading(true);
        let param = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_EMAIL_TEMPLATE_LIST",
            "searchString" : searchString,
            "dropDownValue" : curDropDown.value,
            isOtherApiReq: true
        }

        const response = await requestApiService.processServerRequest(param);
        if (response.issuccess) {
            const data = response.data;
            if(data.length > 0){
                //setListData(data[0]);
                let formData = data[0];
                let listFields = data[1];
                let macroFields = data[2];
                let tableData = await prepareCustomTableData(formData, listFields, false, false);
                let columns = tableData.columns;

                // columns.push(
                //     {
                //         name: "Category",
                //         selector: 'access',
                //         minWidth: '40% !important',
                //         maxWidth: '40% !important',
                //         cell: (row) => (
                //             <div className="ms-1">
                //                 {row["data_row"]["name"].toLowerCase().includes("_customer") ? "Customer Template" : "Agent Template"}
                //             </div>
                //         ),
                //         style: {
                //             padding: '1rem',
                //             margin: 0,
                //             // justifyContent: 'center'
                //         }
                //     },
        
                // );
                columns.push(
                    {
                        name: "Action",
                        selector: 'access',
                        minWidth: '30% !important',
                        maxWidth: '10% !important',
                        cell: (row) => (
                            <div>
                                <Button onClick={() => openModal(row)} className="access-account-btn theme-bg-g">
                                    <span>Edit</span>
                                    <i class="ml-2 fi fi-bs-arrow-up-right-from-square"></i>
                                </Button>
                            </div>
                        ),
                        style: {
                            padding: 0,
                            margin: 0,
                            justifyContent: 'center'
                        }
                    },
        
                );
                setTemplateData(tableData.data);
                setTemplateColumns(columns);
                setMacroFieldItems(macroFields);
                //setCategoryDrop();
            }
        }
        setShowLoading(false);
    }
    const onCKEditorChange = async (content, editor) => {
        //let content = editor.getData();
        setEditorData(content);
        inputData.content = content;
    }
    const saveTempInfo = async () => {
        setShowSaveButtonLoading(true);
        let param = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_TEMPLATE_INFO",
            FORMINPUT: inputData,
            isOtherApiReq: true
        }

        const response = await requestApiService.processServerRequest(param);
        if (response.issuccess) {
            showToast(translateContent("vm.save.success"));
            setShowSaveButtonLoading(false);
        }
        setIsModalOpen(!isModalOpen);
    }

    const testTempInfos = async () =>{
        let email = getSessionValue("loginemail");
        let confirm = await confirmBox("Send Test Email",translateContent("vm.agency.emailtemp.test.mail.confirm",{email: email}),sendEmailIcon,'Send Now','Cancel');
        if(!confirm){
            return
        }
        setShowButtonTestLoading(true);
        let param = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SEND_TEST_TEMPLATE_EMAIL",
            FORMINPUT: inputData,
            toaddress: email,
            isOtherApiReq: true
        }
        const response = await requestApiService.processServerRequest(param);
        if (response.issuccess) {
            showToast(translateContent("vm.agency.emailtemp.test.mail"));
        }
        setShowButtonTestLoading(false);
    }
    const handleInputChange = (e,id = "") => {
        let tmpInputData = { ...inputData };
        let emailAddress = tmpInputData["toaddress"]?.split("###") || ["", ""];
        if (id === "toAddress") {
            emailAddress[0] = e.target.value; 
        } else if (id === "ccAddress") {
            emailAddress[1] = e.target.value; 
        }
        if(id != ""){
            tmpInputData["toaddress"] = emailAddress.join("###");
        }else{
            tmpInputData[e.target.name] = e.target.value;
        }
        setInputData(tmpInputData);
    };
    const toggle = () => {
        setIsModalOpen(!isModalOpen);
    }

    const decodeHtmlEntities = (str) => {
        // const parser = new DOMParser();
        // let decodedString = parser.parseFromString(str, "text/html").body.innerHTML;
        // return decodedString;
        let txt = document.createElement("textarea");
        txt.innerHTML = str;
        return txt.value;
    };
    const cleanHTML = (html) => {
        return html
            .replace(/(<br\s*\/?>\s*){2,}/gi, '<br>') // Replace multiple <br> with a single <br>
            .replace(/<br\s*\/?>\s*(<\/p>|<\/span>)/gi, '$1') // Remove <br> before closing </p> or </span>
            .replace(/<p>\s*<\/p>/gi, '') // Remove completely empty <p> tags
            .replace(/<p>\s*<br\s*\/?>\s*<\/p>/gi, '') // Remove <p> containing only <br>
            .replace(/(<p>\s*)+<br\s*\/?>(\s*<\/p>)+/gi, '') // Remove redundant <p><br></p>
            .trim(); // Trim leading and trailing spaces
    };

    const openModal = (row) =>{
        let mailContent = row["data_row"]["content"];
        row["data_row"]["content"] = decodeHtmlEntities(mailContent);
        setInputData(row["data_row"]);
        setEditorData(cleanHTML(row["data_row"].content));
        toggle();
    }

    const handleSearch = (e) =>{
        let value = e.target.value;
        setSearchString(value);
    }

    const handleOnChange = (name,value) => {
        value = value.replace("$$", "$").replaceAll("&nbsp;", " ");
        inputData.subject = value;
    }

    const dropDownChange = (e) =>{
        setCurDropDown(e);       
    }
    const getModalContent = () =>{
        let emailAddress = inputData?.["toaddress"]?.split("###") || [];
        let toAddress = emailAddress[0] || "";
        let ccAddress = emailAddress[1] || "";
        return (
            <>
                <Modal isOpen={isModalOpen} contentClassName="email-model-wid">
                    <ModalHeader toggle={toggle} />
                    <ModalBody>
                        <Fragment>
                            <form id="agency-form" name="agency" onSubmit={handleSubmit(saveTempInfo)}>
                                <div className="profile-desc cs-domain mx-auto">
                                    {/* Template Name Field */}
                                    <Row className="ms-auto">
                                        <Col md={12}>
                                            <Label>Template Name</Label>
                                            <Controller
                                                name="description"
                                                control={control}
                                                rules={{
                                                    required: !inputData.description ? "Please provide a template name" : false,
                                                }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        id="description"
                                                        autoComplete="off"
                                                        placeholder="Enter Template Name..."
                                                        onChange={(e) => {
                                                            handleInputChange(e);
                                                            field.onChange(e);
                                                        }}
                                                        value={inputData.description}
                                                        className={`form-control mb-2 ${errors.description ? "is-invalid" : ""}`}
                                                        disabled={false}
                                                    />
                                                )}
                                            />
                                            {errors.description && <em className="mb-2 error invalid-feedback">{errors.description.message}</em>}
                                        </Col>
                                    </Row>

                                    {/* Email Subject Field */}
                                    <Row className="ms-auto">
                                        <Col md={12}>
                                            <Label>Email Subject</Label>
                                            <Controller
                                                name="subject"
                                                control={control}
                                                rules={{
                                                    required: !inputData.subject ? "Please provide a subject" : false,
                                                }}
                                                render={({ field }) => (
                                                    <div className="w-100">
                                                        <CratioMention
                                                            name="subject"
                                                            inputID="subject"
                                                            value={inputData.subject}
                                                            fieldkey="id"
                                                            type="type"
                                                            mentionTrigger="$"
                                                            handleOnChange={handleOnChange}
                                                            formname="table_74"
                                                            control={control}
                                                            customClassName="textbox-mention"
                                                            customPanelClassName="panel-mention-subject"
                                                            placeholder="Enter email subject here"
                                                            cols={100}
                                                            suggestionList={selectedMacro}
                                                            rows={1}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            {errors.subject && <em className="mb-2 error invalid-feedback">{errors.subject.message}</em>}
                                        </Col>
                                    </Row>

                                    {/* Email Message Field */}
                                    <Row className="ms-auto">
                                        <Col md={12}>
                                            <Label>Email Message</Label>
                                            <Controller
                                                name="content"
                                                control={control}
                                                rules={{
                                                    required: !editorData ? "Please provide a message" : false,
                                                }}
                                                render={({ field }) => (
                                                    <CKEditorComponent
                                                        {...field}
                                                        isMentionsNeeded={true}
                                                        content={editorData}
                                                        mentionItems={selectedMacro}
                                                        onEditorChange={(event, editor) => {
                                                            onCKEditorChange(event, editor);
                                                            field.onChange(editor.getData());
                                                        }}
                                                        showToolbar={true}
                                                    />
                                                )}
                                            />
                                            {errors.content && <em className="mb-2 error invalid-feedback">{errors.content.message}</em>}
                                            <p>{'Note: Use $ to insert variables.'}</p>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Footer Buttons */}
                                <ModalFooter className="email-model-footer">
                                    {/* Test Template Button */}
                                    <Loading
                                        showLoader={showButtonTestLoading}
                                        mainContent={
                                            <Row className="w-100">
                                                <Col md={6}>
                                                    <Button onClick={testTempInfos} className="email-footer-save theme-bg-g">
                                                        Send Test Email
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    />

                                    {/* Save Template Button */}
                                    <Loading
                                        showLoader={showButtonSaveLoading}
                                        mainContent={
                                            <Row className="w-100">
                                                <Col md={6}>
                                                    <Button type="submit" className="email-footer-save theme-bg-g">
                                                        Save & Publish
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </ModalFooter>
                            </form>
                        </Fragment>
                    </ModalBody>
                </Modal>
            </>
        );
    }
    
    const getTitleBody = () =>{
        return (
            <Fragment>
                <div className='le-form-row'>
                    <div className="gap-2 me-2 input-with-icon">
                        {/* <div className="col">
                            <Select
                                name="selected_form"
                                id="selected_form"
                                placeholder="Category"
                                className="basic-select"
                                classNamePrefix="sub-select wa-select"
                                value={curDropDown} 
                                options={categoryDrop} 
                                onChange={dropDownChange}
                            />
                        </div> */}
                        <div className="input-with-icon col">
                            <span className="search-icon">
                                <i class="icon-color fi fi-rs-search"></i>
                            </span>
                            <Input
                                id="name"
                                autoComplete="off"
                                placeholder="Search by name"
                                onChange={(e) => {
                                    handleSearch(e)
                                }}
                                value={searchString}
                                className='search-input'
                            />
                        </div>
                        <Button className="email-btn theme-bg-g" onClick={() => getTemplateList()}>Search</Button>
                    </div>

                </div>
            </Fragment>
        );
    }
    return (
        <>
        <Fragment>
            {isModalOpen && getModalContent()}
            <Common title="Email Templates" titleContent={
                <div className='d-grid'>
                    <span>Email Templates</span>                   
                </div>
            }
            titleBody={
                getTitleBody()
            }
            mainContent={
                <Container className='max-width-65'>
                    <Loading showLoader={showLoading} mainContent=
                        {
                            <ListTable data={templateData} columns={templateColumns} centeredColumns={true} column = {2}/>
                        }
                    />
                </Container>
            } />
        </Fragment>
        </>
    )

}
export default EmailTemplates;
